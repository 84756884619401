import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import usePost from "../../hooks/usePost";
import { API_ROUTES } from "../../settings/api";
import LoadingButton from "../LoadingButton";

const CreateClientModal = ({ show, setShow, handleSuccess }) => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const { responseData, isPending, error, doPostRequest, reset } = usePost();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    doPostRequest(API_ROUTES.addClient, { name, phone_number: phone });
  };

  useEffect(() => {
    if (responseData) {
      handleSuccess(responseData);
      setShow(false);
      reset();
      setName("");
      setPhone("");
    }
  }, [responseData]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="xl">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Header closeButton>
          <Modal.Title>Новый Клиент</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Имя</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите Имя"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Text className="text-muted">
              Убедитесь, что клиента нет еще в базе
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Телефон</Form.Label>
            <Form.Control
              type="phone"
              placeholder="9 цифр"
              maxLength={9}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          {isPending ? (
            <LoadingButton />
          ) : (
            <Button variant="primary" type="submit">
              Создать
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateClientModal;
