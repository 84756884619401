import React from "react";
import { useDropzone } from "react-dropzone";
import { Badge } from "react-bootstrap";
import drawing from "./images/drawing.png";

const SelectDrawings = ({ setFiles }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles); // Update parent state with accepted files
    },
  });

  const filesList = acceptedFiles.map((file) => (
    <Badge bg="secondary" className="me-2">
      {file.path}
    </Badge>
  ));

  return (
    <>
      <div
        {...getRootProps({
          className: "dropzone",
          multiple: true,
        })}
      >
        <input {...getInputProps()} multiple />
        <div className="border rounded p-5 text-center border-dotted">
          <img className="my-3" width={70} src={drawing} alt="" />
          <p className="text-secondary">
            Перетащите чертежи, или нажмите что-бы выбрать
          </p>
        </div>
        <div className="py-2">{filesList}</div>
      </div>
    </>
  );
};

export default SelectDrawings;
