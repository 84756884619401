import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Container } from "react-bootstrap";
import { API_ROUTES } from "../settings/api";
import useFetch from "../hooks/useFetch";
import Drawings from "../components/OrderDetails/Drawings";
import Info from "../components/OrderDetails/Info";
import Payment from "../components/OrderDetails/Payment";
import "../components/OrderDetails/css/OrderDetails.css";
import OrderAccessories from "../components/OrderDetails/OrderAccessories";

export const Order = () => {
  const { id } = useParams();
  const [fetchKey, setFetchKey] = useState(1);
  const { data: order, isPending, error } = useFetch(API_ROUTES.orderDetails(id), fetchKey);
  const { data: accessories, isAccessoriesPending, accessoriesError } = useFetch(API_ROUTES.getOrderAccessories(id));

  return (
    <div className="Order">
      {order && (
        <Container className="py-3">
          <Row>
            <div className="col-12 col-md-6 my-2">
              <Info order={order} />
            </div>
            <div className="col-12 col-md-6 my-2">
              <Drawings drawings={order.drawings} orderId={order.id} />
            </div>
          </Row>
          <div className="my-3">
            <OrderAccessories accessories={accessories} isPending={isAccessoriesPending} error={accessoriesError} orderId={id} />
          </div>
          <div className="my-3">
            <Payment order={order} setOrderFetchKey={setFetchKey} orderFetchKey={fetchKey} />
          </div>
        </Container>
      )}
      {isPending && <p className="text-secondary">Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default Order;
