const dateTimeOptions = {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

const shortDateTimeOptions = {
  month: "short",
  day: "2-digit",
};

const dateOptions = {
  month: "short",
  day: "2-digit",
  year: "numeric"
}

export const formatDateTime = (dateTime) => {
  // 19 февр. 2024 г., 08:27
  return Intl.DateTimeFormat('ru-RU', dateTimeOptions).format(new Date(dateTime))
}

export const formatDateShort = (dateTime) => {
  // 13 апр.
  return Intl.DateTimeFormat('ru-RU', shortDateTimeOptions).format(new Date(dateTime))
}

export const formatDate = (date) => {
  // 25 апр. 2024 г.
  return Intl.DateTimeFormat('ru-RU', dateOptions).format(new Date(date))
}