export const STATUS_RU = {
    1: "Ожидание",
    2: "Произ-во",
    3: "Готов",
    4: "Выполнен"
}

export const STATUS_COLORS = {
    1: "secondary",
    2: "warning",
    3: "danger",
    4: "success"
}