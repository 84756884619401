import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddDrawings from "../components/CreateOrder/AddDrawings";
import { API_ROUTES } from "../settings/api";
import useFetch from "../hooks/useFetch";
import DrawingsList from "../components/UpdateDrawings/DrawingsList";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const UpdateDrawings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fetchKey, setFetchKey] = useState(1);
  const {
    data: order,
    isPending,
    error,
  } = useFetch(API_ROUTES.orderDetails(id), fetchKey);
  const [deleteError, setDeleteError] = useState(null);
  const axios = useAxiosPrivate();

  const handleDelete = async (drawingId) => {
    try {
      await axios.delete(API_ROUTES.deleteDrawing(drawingId));
      setFetchKey(fetchKey + 1);
    } catch (err) {
      setDeleteError(err.message);
    }
  };

  const handleSuccess = () => {
    navigate(-1, { replace: true });
  };

  return (
    <div className="py-3">
      {order && (
        <div className="container">
          <h3 className="my-3">Управление Чертежами</h3>
          {order.drawings.length >= 1 && (
            <DrawingsList
              drawings={order.drawings}
              handleDelete={handleDelete}
            />
          )}
          <AddDrawings orderId={id} handleSuccess={handleSuccess} />
        </div>
      )}
      {isPending && <p className="text-secondary">Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
      {deleteError && <p className="text-danger">{deleteError}</p>}
    </div>
  );
};

export default UpdateDrawings;
