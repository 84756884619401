import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import UpdatePaymentForm from "./UpdatePaymentForm";

const UpdatePaymentModal = ({payment, show, setShow, handlePaymentAdded}) => {
  const handleSuccess = (response) => {
    setShow(false);
    handlePaymentAdded(response);
  }

  return (
    <Modal // ADD PAYMENT MODAL
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Header>
        <Modal.Title>Добавить оплату</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <UpdatePaymentForm handleSuccess={handleSuccess} payment={payment} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePaymentModal;
