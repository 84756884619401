import axios from 'axios';
import { API_URL } from './api';
import Cookies from 'js-cookie';

export default axios.create({
    baseURL: API_URL
});

export const axiosPrivate = axios.create({
    baseURL: API_URL,
    headers: {"Content-Type": "application/json", "X-CSRFToken": Cookies.get('csrf_token')},
    withCredentials: true
})