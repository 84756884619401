import React from "react";

const AccessoriesStats = ({
  incomes,
  sold,
  purchaseCost,
  salesAmount,
  profit,
}) => {
  return (
    <div className="row justify-content-around">
      <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1">
        <h5>
          <i class="fa-solid fa-circle-arrow-down"></i> {incomes.toLocaleString("ru-RU")} шт.
        </h5>
        <span>ПРИХОД</span>
      </div>
      <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1">
        <h5>
          <i class="fa-solid fa-circle-arrow-up"></i> {sold.toLocaleString("ru-RU")} шт.
        </h5>
        <span>РАСХОД</span>
      </div>
      <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1">
        <h5>
          <i class="fa-solid fa-money-bill-trend-up"></i> $ {purchaseCost.toLocaleString("ru-RU")}
        </h5>
        <span>ЗАКУПКА</span>
      </div>
      <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1">
        <h5>
          <i class="fa-solid fa-file-invoice-dollar"></i> $ {salesAmount.toLocaleString("ru-RU")}
        </h5>
        <span>ПРОДАЖИ</span>
      </div>
      {profit > 0 ? (
        <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1 text-success">
          <h5>
            <i class="fa-solid fa-hand-holding-dollar"></i> $ {profit.toLocaleString("ru-RU")}
          </h5>
          <span>ВЫРУЧКА</span>
        </div>
      ) : (
        <div className="col-11 col-md-3 col-lg-2 mx-3 py-2 border rounded text-center my-1 text-danger">
          <h5>
            <i class="fa-solid fa-hand-holding-dollar"></i> $ {profit.toLocaleString("ru-RU")}
          </h5>
          <span>ВЫРУЧКА</span>
        </div>
      )}
    </div>
  );
};

export default AccessoriesStats;
