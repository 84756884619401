import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import SelectColor from "./SelectColor";
import LoadingButton from "../LoadingButton";
import SelectAccessoryCategory from "./SelectAccessoryCategory";

const UpdateAccessoryForm = ({
  handleSubmit,
  accessory,
  data,
  setData,
  error,
  isPending,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-around">
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Название</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setData({ ...data, name: e.target.value })}
            placeholder="Название"
            value={data.name}
          />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Категория</Form.Label>
          <SelectAccessoryCategory data={data} setData={setData} defaultValue={{value: data.category.id, label: data.category.name}} />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Цвет</Form.Label>
          <SelectColor data={data} setData={setData} />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Фото</Form.Label>
          {accessory.image && (
            <p className="text-muted">
              <a href={accessory.image} target="_blank" rel="noreferrer">
                На данный момент
              </a>{" "}
              Выберите файл, что-бы изменить:
            </p>
          )}
          <Form.Control
            type="file"
            onChange={(e) => setData({ ...data, image: e.target.files[0] })}
          />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Цена продажи</Form.Label>
          <Form.Control
            required
            type="number"
            step={0.01}
            onChange={(e) => setData({ ...data, price: e.target.value })}
            placeholder="USD"
            value={data.price}
          />
        </Form.Group>
        {error && <p className="text-danger">{error}</p>}
        <p className="text-end">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Изменить
            </button>
          )}
        </p>
      </Row>
    </Form>
  );
};

export default UpdateAccessoryForm;
