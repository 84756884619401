import React from "react";
import { Card, ListGroup, Row, Image } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";
import { API_ROUTES } from "../../settings/api";
import noImage from "../../images/noimage.png";

const AccessoryInfoCard = ({ accessoryId }) => {
  const {
    data: accessory,
    isPending,
    error,
  } = useFetch(API_ROUTES.getAccessory(accessoryId));

  return (
    <Card className="text-center">
      {accessory && (
        <>
          <div className="text-center border-bottom p-3">
            {accessory.image ? (
              <Image src={accessory.image} width={280} />
            ) : (
              <Image width={250} src={noImage} />
            )}
          </div>
          <Card.Body>
            <h5>{accessory.name}</h5>
            {accessory.color ? (
              <Row className="justify-content-center mb-2">
                <div
                  className="col-6 p-3 rounded"
                  style={{ backgroundColor: `#${accessory.color.hex_value}` }}
                ></div>
              </Row>
            ) : (
              <p>
                <i className="fa-regular fa-circle-xmark fs-4"></i> Без цвета
              </p>
            )}

            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Ценник:</span>
                <span>$ {accessory.price}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Цена закупки:</span>
                <span>$ {accessory.purchase_price}</span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Приход:</span>
                <span>
                  {accessory.incomes} шт ($ {accessory.incomes_sum})
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Расход:</span>
                <span>
                  {accessory.expenses} шт ($ {accessory.expenses_sum})
                </span>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Общий доход:</span>
                {accessory.total_revenue > 0 ? (
                  <span className="text-success">
                    $ {accessory.total_revenue}
                  </span>
                ) : (
                  <span className="text-danger">
                    $ {accessory.total_revenue}
                  </span>
                )}
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between">
                <span>Текущее Количество:</span>
                {accessory.quantity <= 10 ? (
                  <span className="text-danger">{accessory.quantity} шт</span>
                ) : (
                  <span>{accessory.quantity} шт</span>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </>
      )}
      {isPending && (
        <div className="placeholder-glow text-center pt-4">
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-5">Загрузка...</h5>
        </div>
      )}
      {error && <p className="text-danger">{error}</p>}
    </Card>
  );
};

export default AccessoryInfoCard;
