import React, { useState } from "react";
import { Table } from "react-bootstrap";
import UpdatePaymentModal from "./UpdatePaymentModal";
import { formatDateTime } from "../../settings/dateTime";

const PaymentsTable = ({ payments, isUserBoss, handlePaymentAdded }) => {
  const [updatePaymentId, setUpdatePaymentId] = useState();
  const [updateModalShow, setUpdateModalShow] = useState();

  const handleUpdateClick = (payment) => {
    setUpdatePaymentId(payment);
    setUpdateModalShow(true);
  };

  return (
    <div>
      <Table striped hover>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма</th>
            <th>Примечание</th>
            {isUserBoss && <th></th>}
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>
                {formatDateTime(payment.created_at)}
              </td>
              <td className="fw-bold">$ {payment.amount}</td>
              <td>{payment.comment || "-"}</td>
              {isUserBoss && (
                <td>
                  <span onClick={() => handleUpdateClick(payment)}>
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <UpdatePaymentModal
        payment={updatePaymentId}
        show={updateModalShow}
        setShow={setUpdateModalShow}
        handlePaymentAdded={handlePaymentAdded}
      />
    </div>
  );
};

export default PaymentsTable;
