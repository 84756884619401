import React from "react";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import { formatDateTime } from "../../settings/dateTime";
import clientBg from "../../images/client-bg.jpg";
import clientImg from "../../images/profile.png";
import "./css/Client.css";

const ClientInfo = ({ client, setUpdateModalShow, isUserBoss }) => {
  return (
    <Card className="text-center shadow">
      <Card.Img variant="top" src={clientBg} style={{height: "18rem"}} />
      <Card.ImgOverlay className="d-flex justify-content-center align-items-center client-card-image-wrapper position-relative">
        <Card.Img className="client-image" src={clientImg} />
      </Card.ImgOverlay>
      {client ? (
        <Card.Body>
          <Row className="justify-content-center text-center mb-4">
            <Col xs={4}>
              <div className="stats-item">
                <h6>{client.orders_count}</h6>
                <span className="text-muted fs-6">Заказов</span>
              </div>
            </Col>
            <Col xs={4}>
              <div className="stats-item">
                <h6>{client.total_area}</h6>
                <span className="text-muted">
                  м<sup>2</sup>
                </span>
              </div>
            </Col>
          </Row>
          <h5>{client.name}</h5>
          <p className="text-secondary">+998 {client.phone_number}</p>
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Сумма Заказов:</span>
              <span>$ {client.total_amount}</span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Оплачено:</span>
              <span className="text-success">
                $ {client.total_payments}
              </span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Долг:</span>
              {client.total_debt > 0 ?
                <span className="text-danger">$ {client.total_debt}</span>
                : <span className="text-success">$ {client.total_debt}</span>
              }
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span className="text-secondary">Зарег-ан:</span>
              <span className="text-secondary">
                {formatDateTime(client.date_added)}
              </span>
            </ListGroup.Item>
          </ListGroup>
          {isUserBoss &&
          <button
            className="btn btn-secondary mt-3"
            onClick={() => setUpdateModalShow(true)}
          >
            Изменить
          </button>}
        </Card.Body>
      ) : (
        <div className="placeholder-glow text-center pt-4">
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-12">Загрузка...</h5>
          <h5 className="placeholder col-5">Загрузка...</h5>
        </div>
      )}
    </Card>
  );
};

export default ClientInfo;
