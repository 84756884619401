import React from "react";
import { Table, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const GlassTable = ({ glasses }) => {
  const { isUserBoss } = useAuth();

  return (
    <div>
      <Table className="p-0 m-0" striped hover>
        <thead>
          <tr>
            <th>№</th>
            <th>Толщина</th>
            <th>Цвет</th>
            <th>Ширина</th>
            <th>Высота</th>
            <th>Количество</th>
            {isUserBoss && <th></th>}
          </tr>
        </thead>
        <tbody>
          {glasses.map((glass) => (
            <tr key={glass.id}>
              <th scope="row">{glass.id}</th>
              <td>{glass.color}</td>
              <td>{glass.thickness} мм</td>
              <td>{glass.width}</td>
              <td>{glass.height}</td>
              <td>{glass.quantity}</td>
              {isUserBoss && (
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="py-0">
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="text-center">
                      <Dropdown.Item>
                        <Link
                          className="dropdown-item text-secondary"
                          to={`/inventory/glasses/${glass.id}/update`}
                        >
                          <i className="fa-solid fa-file-pen"></i>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GlassTable;
