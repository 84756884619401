import React, { useEffect, useState } from "react";
import ClientsTable from "../components/ClientList/ClientsTable";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import ClientSearch from "../components/ClientList/ClientsSearch";
import CreateClientModal from "../components/ClientList/CreateClientModal";

const Clients = () => {
  const baseFetchUrl = `${API_ROUTES.getClients}?stats=True`;
  const [fetchUrl, setFetchUrl] = useState(baseFetchUrl);
  const [fetchKey, setFetchKey] = useState(1);
  const { data: clients, isPending, error } = useFetch(fetchUrl, fetchKey);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState();
  const [orderBy, setOrderBy] = useState("descending");
  const [createModalShow, setCreateModalShow] = useState(false);

  useEffect(() => {
    if (sortBy) {
      setFetchUrl(`${baseFetchUrl}&search=${search}&sort_by=${sortBy}&order_by=${orderBy}`);
    } else {
      setFetchUrl(`${baseFetchUrl}&search=${search}`);
    }
    }, [search, sortBy, orderBy, baseFetchUrl]);

  const handleCreate = () => {
    setFetchKey(fetchKey+1);
  }

  return (
    <div>
      {clients && (
        <div>
          <ClientSearch
            setSearch={setSearch}
            setCreateModalShow={setCreateModalShow}
            setSortBy={setSortBy}
            setOrderBy={setOrderBy}
          />
          <ClientsTable clients={clients} setOrderBy={setOrderBy} />
          <CreateClientModal
            show={createModalShow}
            setShow={setCreateModalShow}
            handleSuccess={handleCreate}
          />
        </div>
      )}

      {isPending && <p>Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default Clients;
