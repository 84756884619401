import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { API_ROUTES } from "../../settings/api";
import useFetch from "../../hooks/useFetch";

const ClientChart = ({ clientId }) => {
  const { data, isPending, error } = useFetch(API_ROUTES.clientStats(clientId));

  return (
    <ResponsiveContainer width="100%" height="100%">
      {isPending && <p>Загрузка статисики...</p>}
      {error && <p className="text-danger">{error}</p>}
      {data && (
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={30}
        >
          <XAxis
            dataKey="month"
            scale="point"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar
            dataKey="orders"
            name={"Заказов"}
            fill="#005866"
            background={{ fill: "#eee" }}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default ClientChart;
