export const API_URL = "https://api.starglassonline.uz/"

export const API_ROUTES = {
    changePassword: "user/change-password/",
    login: "user/create-token/",
    refresh: "user/refresh-token/",
    logout: "user/logout/",

    getClients: "clients/",
    getGlasses: "inventory/glasses/",
    getGlass: (glassId) => `inventory/glasses/${glassId}/`,

    getOrders: "orders/",
    createOrder: "orders/create/",
    orderDetails: (orderId) => `orders/${orderId}/`,
    deleteOrder: (orderId) => `orders/${orderId}/delete/`,
    orderUpdate: (orderId) => `orders/${orderId}/update/`,
    orderPayments: (orderId) => `orders/${orderId}/payments/`, 
    changeStatus: (orderId) => `orders/${orderId}/change-status/`, 
    addDrawings: (orderId) => `orders/${orderId}/add-drawings/`,
    addPayment: (orderId) => `orders/${orderId}/add-payment/`,
    updatePayment: (paymentId) => `payments/${paymentId}/update/`,

    deleteDrawing: (drawingId) => `drawings/${drawingId}/delete/`,

    addClient: "clients/",
    updateClient: (clientId) => `clients/${clientId}/`,
    clientDetails: (clientId) => `clients/${clientId}/`,
    clientStats: (clientId) => `clients/${clientId}/stats/`,

    createGlass: "inventory/glasses/create/",
    updateGlass: (glassId) => `inventory/glasses/${glassId}/update/`,

    getAccessories: "inventory/accessories/",
    getAccessoriesWithStats: "inventory/accessories-with-stats/",
    getAccessory: (accessoryId) => `inventory/accessories/${accessoryId}/`,
    accessoryColors: "inventory/accessories/colors/",
    accessoryCategories: "inventory/accessories/categories/",
    createAccessory: "inventory/accessories/create/",
    updateAccessory: (accessoryId) => `inventory/accessories/${accessoryId}/update/`,
    getAccessoryIncome: (accessoryIncomeId) => `inventory/accessories/incomes/${accessoryIncomeId}/`,
    getAccessoryExpenses: (accessoryId) => `inventory/accessories/${accessoryId}/expenses/`,
    getAccessoryIncomes: (accessoryId) => `inventory/accessories/${accessoryId}/incomes/`,
    createAccessoryIncome: (accessoryId) => `inventory/accessories/${accessoryId}/incomes/create/`,
    createAccessoryExpense: (accessoryId) => `inventory/accessories/${accessoryId}/expenses/create/`,
    updateAccessoryIncome: (accessoryIncomeId) => `inventory/accessories/incomes/${accessoryIncomeId}/update/`,

    getOrderAccessories: (orderId) => `orders/${orderId}/accessories/`,
    createOrderAccessory: (orderId) => `orders/${orderId}/accessories/create/`,
    deleteOrderAccessory: (expenseId) => `inventory/accessories/expenses/${expenseId}/delete/`,

    ordersAmount: "orders/total-amount/",
    ordersNumber: "orders/count/",
    ordersInProgressNumber: "orders/in-progress/count/",
}