import React from "react";
import GlassTable from "../components/Inventory/GlassTable";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

const Glasses = () => {
  const {
    data: glasses,
    isPending: glassesLoading,
    error: glassesError,
  } = useFetch(API_ROUTES.getGlasses);

  return (
    <Container fluid>
      <div className="rounded border my-3">
        <div className="p-2 border-bottom">
          <h3 className="d-inline-block">Стекла</h3>
          <Link to="/inventory/glasses/create">
            <button className="btn btn-primary float-end">Добавить</button>
          </Link>
        </div>
        {glassesLoading && <p className="text-secondary">Загрузка...</p>}
        {glassesError && <p className="text-danger">{glassesError}</p>}
        {glasses && <GlassTable glasses={glasses} />}
      </div>
    </Container>
  );
};

export default Glasses;
