import axios from "../settings/axios";
import useAuth from "./useAuth";
import { API_ROUTES } from "../settings/api";
import { jwtDecode } from "jwt-decode";

const useRefreshToken = () => {
  const { setAuth, setTokenData, setIsUserBoss } = useAuth();

  const refresh = async () => {
    const response = await axios.post(API_ROUTES.refresh, null, {withCredentials: true});
    const accessToken = response.data.access;
    const tokenData = jwtDecode(accessToken);
    setAuth((prev) => {
      return { ...prev, accessToken };
    });
    setTokenData(tokenData);
    setIsUserBoss(tokenData.is_boss);
    return accessToken;
  };

  return refresh;
};

export default useRefreshToken;
