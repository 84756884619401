import React, { useState } from "react";
import { Card, ListGroup, Modal, Button } from "react-bootstrap";
import PaymentsTable from "./PaymentsTable";
import AddPaymentForm from "./AddPaymentForm";
import useFetch from "../../hooks/useFetch";
import { API_ROUTES } from "../../settings/api";
import useAuth from "../../hooks/useAuth";

const Payment = ({ order, orderFetchKey, setOrderFetchKey }) => {
  const [show, setShow] = useState();
  const [fetchKey, setFetchKey] = useState(1);
  const handleModalClose = () => setShow(false);
  const handleModalShow = () => setShow(true);
  const { isUserBoss } = useAuth();

  const {
    data: payments,
    isPending,
    error,
  } = useFetch(API_ROUTES.orderPayments(order.id), fetchKey);

  const handlePaymentAdded = (newPayment) => {
    setFetchKey(fetchKey+1);
    setOrderFetchKey(orderFetchKey+1);
  };

  return (
    <Card className="h-100 shadow" id="payment">
      <Card.Header as="h4">Оплата</Card.Header>
      <Card.Body>
        <div className="my-2">
          <ListGroup>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Сумма:</span>
              <span className="fw-bold">$ {order.amount}</span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Оплачено</span>
              <span className="fw-bold text-success">$ {order.total_payment}</span>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Остаток</span>
              <span className="fw-bold text-danger">$ {order.payment_balance}</span>
            </ListGroup.Item>
          </ListGroup>

          {order.total_payment > 0 && payments && (
            <div className="border rounded my-3">
              <PaymentsTable isUserBoss={isUserBoss} payments={payments} handlePaymentAdded={handlePaymentAdded} />
              {isPending && <p className="text-secondary">Loading...</p>}
              {error && <p className="text-danger">{error}</p>}
            </div>
          )}

          {isUserBoss && (
            <div className="add-payment">
              <Button variant="primary mt-2" onClick={handleModalShow}>
                Добавить оплату
              </Button>

              <Modal // ADD PAYMENT MODAL
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
              >
                <Modal.Header>
                  <Modal.Title>Добавить оплату</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <AddPaymentForm
                    orderId={order.id}
                    handleSuccess={handlePaymentAdded}
                    setShow={setShow}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Закрыть
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Payment;
