import { Link } from "react-router-dom";
import { Dropdown, Table } from "react-bootstrap";
import { formatDate } from "../../settings/dateTime";

const ClientsTable = ({ clients, setOrderBy }) => {
  return (
    <div className="border-top">
      <Table className="p-0 m-0" striped hover>
        <thead className="header-sticky">
          <tr>
            <th>№</th>
            <th>Имя клиента</th>
            <th>Телефон</th>
            <th>Заказов</th>
            <th>Сумма заказов (USD)</th>
            <th>Оплачено (USD)</th>
            <th>Задолженность (USD)</th>
            <th>Площадь (м²)</th>
            <th>Дата регистрации</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client) => (
            <tr key={client.id} className={client.deleted && "table-danger"}>
              <th scope="row">{client.id}</th>
              <td>{client.name}</td>
              <td>{client.phone_number}</td>
              <td>{client.orders_count | 0}</td>
              <td>$ {client.total_amount | 0}</td>
              <td>$ {client.total_payments | 0}</td>
              <td>
                {client.total_debt > 0 ? (
                  <span className="text-danger">{client.total_debt}</span>
                ) : (
                  <span className="text-success">0</span> 
                )}
              </td>
              <td>
                {client.total_area || 0} м<sup>2</sup>
              </td>
              <td>{formatDate(client.date_added)}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="" className="py-0">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="text-center">
                    <Dropdown.Item>
                      <Link
                        className="dropdown-item text-primary"
                        to={`/clients/${client.id}`}
                      >
                        <i className="fa-solid fa-file-lines"></i>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ClientsTable;
