import React from "react";
import {
  Form,
  InputGroup,
  Card,
  Image,
  ListGroup,
  Modal,
  Button,
} from "react-bootstrap";
import noImage from "../../images/noimage.png";
import { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { API_ROUTES } from "../../settings/api";

const AccessoriesToChoose = ({
  handleChoose,
  handleUnChoose,
  chosenAccessories,
  show,
  setShow,
}) => {
  const [search, setSearch] = useState();
  const [apiUrl, setApiUrl] = useState(API_ROUTES.getAccessories);
  const { data: accessories, isPending, error } = useFetch(apiUrl);

  useEffect(() => {
    setApiUrl(`${API_ROUTES.getAccessories}?search=${search}`);
  }, [search]);

  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      scrollable
      dialogClassName="modal-80w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Выберите аксессуары</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        {accessories && (
          <>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputGroup>
                  <button type="submit" className="btn bg-starglass">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                  <Form.Control
                    type="search"
                    placeholder="Поиск"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="row justify-content-between">
              {accessories.map((accessory) => (
                <Card
                  className="text-center col-11 col-md-2 m-3"
                  key={accessory.id}
                >
                  <>
                    <div className="text-center border-bottom p-3">
                      {accessory.image ? (
                        <Image width={50} height={50} src={accessory?.image} />
                      ) : (
                        <Image width={50} height={50} src={noImage} />
                      )}
                    </div>
                    <Card.Body>
                      <h6>{accessory.name}</h6>
                      <ListGroup>
                        <ListGroup.Item
                          style={
                            accessory.color
                              ? {
                                  backgroundColor: `#${accessory.color.hex_value}`,
                                }
                              : { backgroundColor: "#fff" }
                          }
                        ></ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span>Цена:</span>
                          <span>$ {accessory.price}</span>
                        </ListGroup.Item>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <span>Кол-во:</span>
                          {accessory.quantity <= 10 ? (
                            <span className="text-danger">
                              {accessory.quantity}
                            </span>
                          ) : (
                            <span>{accessory.quantity}</span>
                          )}
                        </ListGroup.Item>
                      </ListGroup>
                      {accessory.quantity > 0 ? (
                        chosenAccessories.includes(accessory) ? (
                          <button
                            className="btn btn-success mt-3"
                            onClick={() => handleUnChoose(accessory)}
                          >
                            <i class="fa-solid fa-square-check"></i>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mt-3"
                            onClick={() => handleChoose(accessory)}
                          >
                            <i className="fa-solid fa-square-plus"></i>
                          </button>
                        )
                      ) : (
                        <button className="btn btn-secondary mt-3" disabled>
                          <i className="fa-solid fa-square-plus"></i>
                        </button>
                      )}
                    </Card.Body>
                  </>
                </Card>
              ))}
            </div>
          </>
        )}
        {accessories?.length < 1 && (
          <h5 className="text-center text-muted py-5">По данному запросу ничего не найдено!</h5>
        )}
        {isPending && <p className="text-secondary">Загрузка...</p>}
        {error && <p className="text-danger">{error}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShow(false)}>
          Готово
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccessoriesToChoose;
