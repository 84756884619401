import React from "react";

export default function LoadingButton() {
  return (
    <button disabled className="btn btn-primary">
      <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
      <span role="status">Загрузка...</span>
    </button>
  );
}
