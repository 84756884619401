import React from "react";
import { Card, Image, InputGroup, Form } from "react-bootstrap";
import noImage from "../../images/noimage.png";

const ChosenAccessories = ({
  accessories,
  handleUnChoose,
  handleInputChange,
}) => {
  return (
    <div className="row justify-content-start">
      {accessories.map((accessory) => (
        <Card
          className="text-center col-11 col-md-3 m-3"
          style={
            accessory.color && {
              boxShadow: `0 0 10px 2px #${accessory.color.hex_value}`,
            }
          }
        >
          <>
            <div className="text-center border-bottom p-3">
              {accessory.image ? (
                <Image width={50} height={50} src={accessory?.image} />
              ) : (
                <Image width={50} height={50} src={noImage} />
              )}
              <span
                onClick={() => handleUnChoose(accessory)}
                className="btn position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success fs-6"
              >
                <i class="fa-solid fa-square-check"></i>
              </span>
            </div>
            <Card.Body>
              <h6>
                {accessory.name} {accessory.color.name}
              </h6>
              <p className="text-muted">Доступно: {accessory.quantity} шт.</p>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <i className="fa-solid fa-money-check-dollar"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Цена"
                  required
                  defaultValue={accessory.price}
                  step={0.01}
                  onChange={(e) =>
                    handleInputChange(accessory.id, "price", e.target.value)
                  }
                />
              </InputGroup>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <i className="fa-solid fa-arrow-up-1-9"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  required
                  placeholder="Кол-во"
                  max={accessory.quantity}
                  min={0}
                  onChange={(e) =>
                    handleInputChange(accessory.id, "quantity", e.target.value)
                  }
                />
              </InputGroup>
              <InputGroup className="mb-2">
                <InputGroup.Text>
                  <i className="fa-regular fa-message"></i>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Коммент"
                  onChange={(e) =>
                    handleInputChange(accessory.id, "comment", e.target.value)
                  }
                />
              </InputGroup>
            </Card.Body>
          </>
        </Card>
      ))}
    </div>
  );
};

export default ChosenAccessories;
