import React from "react";
import { Row, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import noaccessories from "../../images/noincomes.png";
import OrderAccessoryList from "../CreateOrder/OrderAccessoryList";

const OrderAccessories = ({ accessories, orderId, isPending, error }) => {
  return (
    <Card className="h-100 shadow">
      <Card.Header as="h4">
        <span className="me-2">Аксессуары</span>
        <Link
          to={`/orders/${orderId}/update-accessories`}
          className="text-secondary"
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </Link>
      </Card.Header>
      <Card.Body className="py-4">
        {accessories && accessories?.length > 0 ? (
          <Row className="justify-content-center">
            <OrderAccessoryList accessories={accessories} />
          </Row>
        ) : (
          <div className="empty-today-orders">
            <Image width={"100px"} src={noaccessories}></Image>
            <p className="text-secondary">Аксессуаров к заказу нет!</p>
          </div>
        )}
        {isPending && <p>Загрузка...</p>}
        {error && <p className="text-danger">{error}</p>}
      </Card.Body>
    </Card>
  );
};

export default OrderAccessories;
