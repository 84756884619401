import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Inventory = () => {
  return (
    <Container>
      <div className="row py-3">
        <Link to={'/inventory/glasses/'} className="text-decoration-none p-0">
          <div className="col-12 p-5 text-center border rounded shadow my-3 bg-starglass">
            <h3>Стекла</h3>
          </div>
        </Link>
        <Link to={'/inventory/accessories/'} className="text-decoration-none p-0">
          <div className="col-12 p-5 text-center border rounded shadow mt-3 bg-starglass">
            <h3>Аксессуары</h3>
          </div>
        </Link>
      </div>
    </Container>
  );
};

export default Inventory;
