import React, { useEffect, useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API_ROUTES } from "../settings/api";
import LoadingButton from "../components/LoadingButton";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useFetch from "../hooks/useFetch";

const UpdateGlass = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const [updateError, setUpdateError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {data: glass, isPending, error} = useFetch(API_ROUTES.getGlass(id));
  const [data, setData] = useState({});

  useEffect(() => {
    setData(glass);
  }, [glass])

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(API_ROUTES.updateGlass(id), data)
      .then((res) => {
        setUpdateError(null);
        setIsLoading(false);
        navigate(-1);
      })
      .catch((error) => {
        setUpdateError(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <div className="p-3 my-5 border shadow-lg rounded">
        {error && <p className="text-danger">{error}</p>}
        {!isPending && !error && 
        <Form onSubmit={handleSubmit}>
          <h4 className="mb-3 pb-1 border-bottom">Изменить Стекло</h4>
          <Row className="justify-content-around">
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Толщина (мм)</Form.Label>
              <Form.Control
                type="number"
                required
                step={0.01}
                onChange={(e) =>
                  setData({ ...data, thickness: e.target.value })
                }
                defaultValue={data?.thickness}
                placeholder="Толщина стекла"
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Цвет</Form.Label>
              <Form.Control
                required
                onChange={(e) => setData({ ...data, color: e.target.value })}
                type="text"
                defaultValue={data?.color}
                placeholder='Например: "Qora"'
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Ширина Листа (мм)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Ширина Листа"
                defaultValue={data?.width}
                onChange={(e) => setData({ ...data, width: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Высота Листа (мм)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Высота Листа"
                defaultValue={data?.height}
                onChange={(e) => setData({ ...data, height: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Количество (шт)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Количество листов"
                defaultValue={data?.width}
                onChange={(e) => setData({ ...data, quantity: e.target.value })}
              />
            </Form.Group>
          </Row>
          {updateError && <p className="text-danger">{updateError}</p>}
          <p className="text-end">
            {isLoading ? (
              <LoadingButton />
            ) : (
              <button type="submit" className="btn btn-primary">
                Создать
              </button>
            )}
          </p>
        </Form>}
      </div>
    </Container>
  );
};

export default UpdateGlass;
