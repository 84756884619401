import React from "react";
import { useParams } from "react-router-dom";
import UpdateOrderForm from "../components/UpdateOrder/UpdateOrderForm";
import { useNavigate } from "react-router-dom";

const UpdateOrder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate(-1);
  };

  return (
    <div className="container py-4">
      <UpdateOrderForm id={id} handleSuccess={handleSuccess} />
    </div>
  );
};

export default UpdateOrder;
