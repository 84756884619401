import { Routes, Route } from "react-router-dom";
import React from "react";
import Orders from "./pages/Orders";
import Order from "./pages/Order";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import CreateOrder from "./pages/CreateOrder";
import UpdateOrder from "./pages/UpdateOrder";
import UpdateDrawings from "./pages/UpdateDrawings";
import Inventory from "./pages/Inventory";
import CreateGlass from "./pages/CreateGlass";
import UpdateGlass from "./pages/UpdateGlass";
import Main from "./pages/Main";
import Clients from "./pages/Clients";
import Client from "./pages/Client";
import ChangePassword from "./pages/ChangePassword";
import Glasses from "./pages/Glasses";
import Accessories from "./pages/Accessories";
import CreateAccessory from "./pages/CreateAccessory";
import UpdateAccessory from "./pages/UpdateAccessory";
import AccessoryIncome from "./pages/AccessoryIncome";
import AccessoryDetails from "./pages/AccessoryDetails";
import UpdateAccessoryIncome from "./pages/UpdateAccessoryIncome";
import UpdateOrderAccessories from "./pages/UpdateOrderAccessories";
import ReaderGuard from "./components/ReaderGuard";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Main />} />
              <Route path="/user/change-password" element={<ChangePassword />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/:id" element={<Order />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:id" element={<Client />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/inventory/glasses" element={<Glasses />} />
              <Route path="/inventory/accessories" element={<Accessories />} />
              <Route path="/inventory/accessories/:id" element={<AccessoryDetails />} />

              {/* Nest Create and Update routes inside ReaderGuard */}
              <Route element={<ReaderGuard />}>
                <Route path="/create-order" element={<CreateOrder />} />
                <Route path="/orders/:id/update" element={<UpdateOrder />} />
                <Route path="/orders/:id/update-drawings" element={<UpdateDrawings />} />
                <Route path="/orders/:orderId/update-accessories" element={<UpdateOrderAccessories />} />
                <Route path="/inventory/glasses/create" element={<CreateGlass />} />
                <Route path="/inventory/glasses/:id/update" element={<UpdateGlass />} />
                <Route path="/inventory/accessories/create" element={<CreateAccessory />} />
                <Route path="/inventory/accessories/:id/update" element={<UpdateAccessory />} />
                <Route path="/inventory/accessories/:id/income" element={<AccessoryIncome />} />
                <Route path="/inventory/accessories/incomes/:id/update" element={<UpdateAccessoryIncome />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
