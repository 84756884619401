import React from "react";
import { Outlet } from "react-router-dom";
import AppNavbar from "./Navbar/AppNavbar";

const Layout = () => {
  return (
    <div className="App">
      <AppNavbar pageTitle="Star Glass" />
      <Outlet />
    </div>
  );
};

export default Layout;
