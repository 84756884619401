import React from "react";
import { Container } from "react-bootstrap";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import { Link } from "react-router-dom";
import AccessoriesCards from "../components/Inventory/AccessoriesCards";
import { useState } from "react";
import AccessoryFilters from "../components/Inventory/AccessoryFilters";
import AccessoriesStats from "../components/Inventory/AccessoriesStats";

const Accessories = () => {
  const [apiUrl, setApiUrl] = useState(API_ROUTES.getAccessoriesWithStats);
  const { data, isPending, error } = useFetch(apiUrl);

  return (
    <Container fluid>
      <div className="rounded border my-3">
        <div className="p-2 border-bottom">
          <h3 className="d-inline-block">Аксессуары</h3>
          <Link to="/inventory/accessories/create">
            <button className="btn btn-primary float-end">Добавить</button>
          </Link>
        </div>
        <AccessoryFilters setApiUrl={setApiUrl} />
        {data && (
          <AccessoriesStats
            incomes={data.total_incoming_quantity}
            sold={data.total_sold_quantity}
            purchaseCost={data.total_purchase_cost}
            salesAmount={data.total_sales_amount}
            profit={data.total_profit}
          />
        )}
        {isPending && <p className="text-secondary">Загрузка...</p>}
        {error && <p className="text-danger">{error}</p>}
        {data && <AccessoriesCards accessories={data?.accessories} />}
      </div>
    </Container>
  );
};

export default Accessories;
