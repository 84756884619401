import useAuth from "./useAuth";
import { API_ROUTES } from "../settings/api";
import useAxiosPrivate from "./useAxiosPrivate";

const useLogout = () => {
    const { setAuth } = useAuth();
    const axios = useAxiosPrivate();
    
    const logout = async () => {
        setAuth({})
        try {
            await axios(API_ROUTES.logout, {
                withCredentials: true
            });
        } catch(err) {
            console.error(err);
        }
    }

    return logout
}

export default useLogout;