import React, { useState } from "react";
import { Row, Container } from "react-bootstrap";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import OrderAccessoryDeleteModal from "../components/CreateOrder/OrderAccessoryDeleteModal";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OrderAccessoryList from "../components/CreateOrder/OrderAccessoryList";
import OrderAccessory from "../components/CreateOrder/OrderAccessory";

const UpdateOrderAccessories = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [fetchKey, setFetchKey] = useState(0);
  const axios = useAxiosPrivate();
  const [deleteError, setDeleteError] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteAccessoryId, setDeleteAccessoryId] = useState(null);

  const handleDeleteClick = (accessoryId) => {
    setDeleteAccessoryId(accessoryId);
    setDeleteModalShow(true);
  };

  const handleDelete = () => {
    axios
      .delete(API_ROUTES.deleteOrderAccessory(deleteAccessoryId))
      .then((res) => {
        setDeleteError(null);
        setFetchKey(fetchKey + 1);
        setDeleteModalShow(false);
        setDeleteAccessoryId(null);
      })
      .catch((error) => {
        setDeleteError(error.message);
      });
  };

  const {
    data: accessories,
    isPending,
    error,
  } = useFetch(API_ROUTES.getOrderAccessories(orderId), fetchKey);

  return (
    <Container className="p-3 my-3">
      <h4 className="mb-3 pb-1 border-bottom">Добавить Аксессуар</h4>
      <OrderAccessory handleSuccess={() => navigate(-1)} orderId={orderId} />
      {accessories?.length > 0 && (
        <>
          <hr />
          <div className="mt-3 py-2">
            <h4>Выбранные</h4>
            <Row>
              <OrderAccessoryList accessories={accessories} deleteButton={true} handleDeleteClick={handleDeleteClick} />
            </Row>
            {deleteError && <p className="text-danger">{error}</p>}
          </div>
        </>
      )}
      {isPending && <p>Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
      <OrderAccessoryDeleteModal
        show={deleteModalShow}
        setShow={setDeleteModalShow}
        accessoryId={deleteAccessoryId}
        handleDelete={handleDelete}
      />
      <hr />
      <div>
        <button className="btn btn-success" onClick={() => navigate(-1)}>
          Назад
        </button>
      </div>
    </Container>
  );
};

export default UpdateOrderAccessories;
