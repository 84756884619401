import React from "react";
import { Modal, Button } from "react-bootstrap";

const OrderAccessoryDeleteModal = ({ show, setShow, handleDelete }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Header>
        <Modal.Title>
          Вы уверены, что хотите удалить выбранный объект?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Запись не удастся вернуть, придется создать снова.
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          Да, удалить
        </Button>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Отменить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderAccessoryDeleteModal;
