import React from "react";
import { API_ROUTES } from "../../settings/api";
import Select from "react-select";
import { Form } from "react-bootstrap";
import useFetch from "../../hooks/useFetch";

const SelectGlassType = ({
  setGlass,
  isMulti,
  setMultipleGlasses,
  selectedGlassOptions,
  setSelectedGlassOptions,
  defaultValue,
}) => {
  const {
    data: fetchedGlasses,
    isPending,
    error,
  } = useFetch(API_ROUTES.getGlasses);

  const options = [];

  for (let i = 0; i < fetchedGlasses?.length; i++) {
    let glass = fetchedGlasses[i];
    options.push({
      value: glass.id,
      label: `${glass.thickness}mm ${glass.color}`,
    });
  }

  const handleMultipleChange = (selectedOptions) => {
    setSelectedGlassOptions(selectedOptions);
    let selectedValues = [];
    selectedOptions.forEach((element) => {
      selectedValues.push(element.value);
    });
    setMultipleGlasses(selectedValues);
  };

  return (
    <>
      {!isMulti && <Form.Label>Тип стекла</Form.Label>}
      {isPending && <p>Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
      {isMulti ? (
        <Select
          isMulti
          options={options}
          onChange={handleMultipleChange}
          value={selectedGlassOptions}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          options={options}
          onChange={(e) => setGlass(e.value)}
        />
      )}
    </>
  );
};

export default SelectGlassType;
