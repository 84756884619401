import React from "react";
import { Pagination } from "react-bootstrap";

const OrderPagination = ({ page, setPage, next, previous, count }) => {
  const ordersPerPage = 120;
  const totalPages = Math.ceil(count / ordersPerPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const renderPageNumbers = () => {
    let startPage = Math.max(1, page - 2);
    let endPage = Math.min(totalPages, page + 2);

    // If there are less than 5 pages total, adjust the start and end pages accordingly
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === page} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    // Include ellipsis if the range does not include the first or last page
    if (startPage > 1) {
      pageNumbers.unshift(
        <Pagination.Ellipsis key="ellipsisStart" disabled />
      );
    }
    if (endPage < totalPages) {
      pageNumbers.push(
        <Pagination.Ellipsis key="ellipsisEnd" disabled />
      );
    }

    return pageNumbers;
  };

  return (
    <div className="my-2">
      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
        {renderPageNumbers()}
        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
      </Pagination>
    </div>
  );
};

export default OrderPagination;