import React from "react";
import { Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_ROUTES } from "../../settings/api";
import { formatDateShort } from "../../settings/dateTime";
import empty from "../../images/empty.png";

const AccessoryExpensesTable = ({ accessoryId }) => {

  const {
    data: expenses,
    isPending,
    error,
  } = useFetch(API_ROUTES.getAccessoryExpenses(accessoryId));

  return (
    <>
      {expenses?.length > 0 ? (
        <>
        <Table className="p-0 m-0" striped hover>
          <thead>
            <tr>
              <th>Заказ</th>
              <th>Дата</th>
              <th>Кол-во</th>
              <th>Цена</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            {expenses.map((expense) => (
              <tr key={expense.id}>
                <td>
                  {expense?.order ? (
                    <Link to={`/orders/${expense.order.id}`}>
                      {expense?.order?.client?.name}
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{formatDateShort(expense.date)}</td>
                <td>{expense.quantity} шт</td>
                <td>$ {expense.price}</td>
                <td>$ {expense.amount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </>
      ) : (
        <div className="empty-today-orders">
          <Image width={"100px"} src={empty}></Image>
          <p className="text-secondary">Записей нет!</p>
        </div>
      )}
      {isPending && <p>Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
    </>
  );
};

export default AccessoryExpensesTable;
