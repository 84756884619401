import React from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { API_ROUTES } from "../../settings/api";
import SelectAccessoryCategoryMultiple from "./SelectAccessoryCategoryMultiple";
import SelectColorMultiple from "./SelectColorMultiple";

const AccessoryFilters = ({ setApiUrl }) => {
  const [search, setSearch] = useState();
  const [categories, setCategories] = useState();
  const [colors, setColors] = useState();
  const [sortBy, setSortBy] = useState();
  const [order, setOrder] = useState("descending"); // WORKS ONLY WITH SORTBY
  const [filtersShow, setFiltersShow] = useState(false);

  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState();
  const [selectedColorOptions, setSelectedColorOptions] = useState();

  const handleReset = () => {
    setSearch();
    setCategories();
    setColors();
    setSortBy();
    setOrder("descending");
    setSelectedCategoryOptions();
    setSelectedColorOptions();
    setApiUrl(`${API_ROUTES.getAccessoriesWithStats}`);
    setFiltersShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let query_params = "";
    if (search) {
      query_params += `search=${search}`;
    }
    if (categories) {
      categories.forEach((categoryId) => {
        query_params += `category=${categoryId}&`;
      });
    }
    if (colors) {
      colors.forEach((colorId) => {
        query_params += `color=${colorId}&`;
      });
    }
    if (sortBy) {
      query_params += `sort_by=${sortBy}&`;
      if (order) {
        query_params += `order=${order}&`;
      }
    }
    setApiUrl(`${API_ROUTES.getAccessoriesWithStats}?${query_params}`);
    setFiltersShow(false);
  };

  return (
    <Form onSubmit={handleSubmit} id="form">
      <div className="row p-2 pt-3">
        <div className="col-md-3 col-8">
          <InputGroup>
            <button type="submit" className="btn bg-starglass">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
            <Form.Control
              type="search"
              placeholder="Поиск"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputGroup>
        </div>
        <div className="col-md-9 col-4">
          <button
            type="button"
            className="btn bg-starglass float-end"
            onClick={() => setFiltersShow(!filtersShow)}
          >
            <i className="fa-solid fa-filter"></i>
          </button>
          {(categories || colors || search || sortBy) && (
            <Button
              variant="danger"
              className="float-end me-1"
              type="reset"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          )}
        </div>
      </div>
      <Modal
        show={filtersShow}
        onHide={() => setFiltersShow(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Фильтр</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-4">
            <h5>Категория</h5>
            <div className="row">
              <div className="col-12 mb-2 mb-md-0">
                <SelectAccessoryCategoryMultiple
                  selectedCategoryOptions={selectedCategoryOptions}
                  setSelectedCategoryOptions={setSelectedCategoryOptions}
                  setCategories={setCategories}
                />
              </div>
            </div>
          </div>
          <div>
          <div className="my-4">
            <h5>Цвет</h5>
            <div className="row">
              <div className="col-12 mb-2 mb-md-0">
                <SelectColorMultiple
                  selectedColorOptions={selectedColorOptions}
                  setSelectedColorOptions={setSelectedColorOptions}
                  setColors={setColors}
                />
              </div>
            </div>
          </div>
            <div className="row">
              <h5>Сортировка</h5>
              <div className="col-12 mb-2 mb-md-0">
                  <Form.Select onChange={(e) => setSortBy(e.target.value)} defaultValue={sortBy}>
                    <option value="">Нет</option>
                    <option value="quantity">Количеству</option>
                    <option value="price">Цене</option>
                  </Form.Select>
              </div>
              {sortBy &&
              <div className="col-12 mt-2 mb-2 mb-md-0">
                  <Form.Select onChange={(e) => setOrder(e.target.value)} defaultValue={order}>
                    <option value="descending">По убыванию</option>
                    <option value="ascending">По возрастанию</option>
                  </Form.Select>
              </div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(categories || colors || search || sortBy) && (
            <Button variant="danger" type="reset" onClick={handleReset}>
              Сбросить
            </Button>
          )}
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Применить
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default AccessoryFilters;
