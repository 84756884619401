import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../settings/api";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import CreateAccessoryForm from "../components/Inventory/CreateAccessoryForm";

const CreateAccessory = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("category", data.category)

    if (data.color) {
      formData.append("color", data.color);
    }

    if (data.image) {
      formData.append("image", data.image);
    }

    console.log(data);

    axios
      .post(API_ROUTES.createAccessory, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setError(null);
        setIsPending(false);
        navigate(-1);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  return (
    <Container>
      <div className="p-3 my-5 border shadow-lg rounded">
        <h4 className="mb-3 pb-1 border-bottom">Добавить Аксессуар</h4>

        <CreateAccessoryForm
          handleSubmit={handleSubmit}
          data={data}
          setData={setData}
          isPending={isPending}
        />

        {error && <p className="text-danger">{error}</p>}
      </div>
    </Container>
  );
};

export default CreateAccessory;
