import React from "react";
import { API_ROUTES } from "../../settings/api";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";

const SelectColorMultiple = ({setColors, selectedColorOptions, setSelectedColorOptions}) => {
  const {
    data: fetchedColors,
    isPending,
    error,
  } = useFetch(API_ROUTES.accessoryColors);

  const options = [{value: "0", label: "Без цвета"}];

  for (let i = 0; i < fetchedColors?.length; i++) {
    let color = fetchedColors[i];
    options.push({
      value: color.id,
      label: color.name,
    });
  }

  const handleChange = (selectedOptions) => {
    setSelectedColorOptions(selectedOptions);
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setColors(selectedValues);
  };

  return (
    <>
      {isPending && <p className="text-muted">Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
        <Select
          isMulti
          options={options}
          onChange={handleChange}
          value={selectedColorOptions}
        />
    </>
  );
};

export default SelectColorMultiple;