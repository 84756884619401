import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"

const usePost = () => {
  const [responseData, setResponseData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const axios = useAxiosPrivate();

  const doPostRequest = (url, postData) => {
    setIsPending(true);
    axios
      .post(url, postData)
      .then((res) => {
        setResponseData(res.data);
        setIsPending(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  const reset = () => {
    setError(null);
    setIsPending(false);
  }

  return { responseData, isPending, error, doPostRequest, reset };
};

export default usePost;