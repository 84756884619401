import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import SelectColor from "./SelectColor";
import LoadingButton from "../LoadingButton";
import SelectAccessoryCategory from "./SelectAccessoryCategory";

const CreateAccessoryForm = ({ handleSubmit, data, setData, isPending }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-around">
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Название</Form.Label>
          <Form.Control
            type="text"
            required
            onChange={(e) => setData({ ...data, name: e.target.value })}
            placeholder="Название"
          />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Категория</Form.Label>
          <SelectAccessoryCategory data={data} setData={setData} />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Цвет</Form.Label>
          <SelectColor data={data} setData={setData} />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Фото</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setData({ ...data, image: e.target.files[0] })}
          />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Цена продажи</Form.Label>
          <Form.Control
            required
            type="number"
            step={0.01}
            onChange={(e) => setData({ ...data, price: e.target.value })}
            placeholder="USD"
          />
        </Form.Group>
        <p className="text-end">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Создать
            </button>
          )}
        </p>
      </Row>
    </Form>
  );
};

export default CreateAccessoryForm;
