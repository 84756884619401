import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import { Link } from "react-router-dom";
import TodayOrdersTable from "../components/Main/TodayOrdersTable";
import emptyImage from "../images/empty-2.png";

const Main = () => {
  const today = new Date().toISOString().split("T")[0];
  const {
    data: orders,
    isOrdersPending,
    ordersError,
  } = useFetch(`${API_ROUTES.getOrders}?date_from=${today}`);

  const {
    data: ordersAmount,
    isOrdersAmountPending,
    ordersAmountError,
  } = useFetch(API_ROUTES.ordersAmount);

  const {
    data: ordersNumber,
    isOrdersNumberPending,
    ordersNumberError,
  } = useFetch(API_ROUTES.ordersNumber);

  const {
    data: ordersInProgressNumber,
    isOrdersInProgressNumberPending,
    ordersInProgressNumberError,
  } = useFetch(API_ROUTES.ordersInProgressNumber);

  return (
    <Container fluid>
      <Row className="my-2 my-md-4 justify-content-between">
        <Col className="col-12 col-md-8">
          <div className="today-orders border rounded p-3 shadow-lg mb-3 overflow-scroll">
            {!isOrdersPending && orders && orders.count > 0 ? (
              <>
                <h3>Сегодняшние заказы</h3>
                <TodayOrdersTable orders={orders} />
              </>
            ) : (
              <div className="empty-today-orders">
                <div className="empty-image text-center">
                  <img src={emptyImage} alt="" width={"200px"} />
                  <p className="text-secondary fw-bold fs-5">
                    Сегодня заказов пока нет!
                  </p>
                </div>
              </div>
            )}
            {isOrdersPending && <p>Загрузка...</p>}
            {ordersError && <p className="text-danger">{ordersError}</p>}
          </div>
        </Col>

        <Col className="col-12 col-md-4">
          <Row className="justify-content-around">
            <Link
              to="/orders"
              className="col-12 col-md-5 text-decoration-none p-0"
            >
              <Col className="rounded main-page-button py-3 mb-3 border shadow">
                <div>
                  <h2 className="color-dark-blue">
                    <i className="fa-solid fa-list"></i>
                  </h2>
                  <span className="text-secondary fw-bold">Все Заказы</span>
                </div>
              </Col>
            </Link>
            <Link
              to="/create-order"
              className="col-12 col-md-5 text-decoration-none p-0"
            >
              <Col className="rounded main-page-button py-3 mb-3 border shadow">
                <div>
                  <h2 className="color-dark-blue">
                    <i className="fa-regular fa-square-plus"></i>
                  </h2>
                  <span className="text-secondary fw-bold">Новый Заказ</span>
                </div>
              </Col>
            </Link>
            <Link
              to="/inventory"
              className="col-12 col-md-5 text-decoration-none p-0"
            >
              <Col className="rounded main-page-button mb-3 py-3 border shadow">
                <div>
                  <h2 className="color-dark-blue">
                    <i className="fa-solid fa-boxes-stacked"></i>
                  </h2>
                  <span className="text-secondary fw-bold">Склад</span>
                </div>
              </Col>
            </Link>
            <Link
              to="/clients"
              className="col-12 col-md-5 text-decoration-none p-0"
            >
              <Col className="rounded main-page-button py-3 mb-3 border shadow">
                <div>
                  <h2 className="color-dark-blue">
                    <i className="fa-solid fa-user"></i>
                  </h2>
                  <span className="text-secondary fw-bold">Клиенты</span>
                </div>
              </Col>
            </Link>
          </Row>

          <div className="col-12 border rounded shadow p-2 mt-2">
            <Row>
              <div className="col-8">
                <p className="text-secondary fw-bold">СУММА ЗАКАЗОВ</p>
                {ordersAmount && (
                  <h3 className="color-dark-blue">
                    $ {ordersAmount.orders_amount.toLocaleString("ru-RU")}
                  </h3>
                )}
                {isOrdersAmountPending && (
                  <h3 className="placeholder-glow">
                    <span className="placeholder rounded col-8"></span>
                  </h3>
                )}
                {ordersAmountError && (
                  <h3 className="text-danger">{ordersAmountError}</h3>
                )}
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <span className="text-secondary fs-1">
                  <i className="fa-solid fa-hand-holding-dollar"></i>
                </span>
              </div>
            </Row>
          </div>

          <div className="col-12 border rounded shadow p-2 mt-3">
            <Row>
              <div className="col-8">
                <p className="text-secondary fw-bold">КОЛИЧЕСТВО ЗАКАЗОВ</p>
                {ordersNumber && (
                  <h3 className="color-dark-blue">
                    {ordersNumber.orders_count.toLocaleString("ru-RU")}
                  </h3>
                )}
                {isOrdersNumberPending && (
                  <h3 className="placeholder-glow">
                    <span className="placeholder rounded col-8"></span>
                  </h3>
                )}
                {ordersNumberError && (
                  <h3 className="text-danger">{ordersNumberError}</h3>
                )}
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <span className="text-secondary fs-1">
                  <i className="fa-solid fa-money-check"></i>
                </span>
              </div>
            </Row>
          </div>

          <div className="col-12 border rounded shadow p-2 mt-3">
            <Row>
              <div className="col-8">
                <p className="text-secondary fw-bold">АКТИВНЫЕ ЗАКАЗЫ</p>
                {ordersInProgressNumber && (
                  <h3 className="color-dark-blue">
                    {ordersInProgressNumber.orders_count.toLocaleString(
                      "ru-RU"
                    )}
                  </h3>
                )}
                {isOrdersInProgressNumberPending && (
                  <h3 className="placeholder-glow">
                    <span className="placeholder rounded col-8"></span>
                  </h3>
                )}
                {ordersInProgressNumberError && (
                  <h3 className="text-danger">{ordersInProgressNumberError}</h3>
                )}
              </div>

              <div className="col-4 d-flex align-items-center justify-content-center">
                <span className="text-secondary fs-1">
                  <i className="fa-solid fa-spinner"></i>
                </span>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
