import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

const ClientsFilterModal = ({
  show,
  setShow,
  handleSubmit,
  sortBy,
  setSortBy,
  orderBy,
  setOrderBy,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="xl">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Header closeButton>
          <Modal.Title>Фильтр</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Сортировка</Form.Label>
            <Form.Select
              onChange={(e) => setSortBy(e.target.value)}
              defaultValue={sortBy}
            >
              <option value="">Нет</option>
              <option value="total_amount">Сумма заказов</option>
              <option value="total_payments">Оплата</option>
              <option value="total_area">Квадратура</option>
              <option value="total_debt">Задолженность</option>
            </Form.Select>
          </Form.Group>
          {sortBy && (
            <Form.Group className="mb-3">
              <Form.Select onChange={(e) => setOrderBy(e.target.value)} defaultValue={orderBy}>
                <option value="descending">
                  По убыванию
                </option>
                <option value="ascending">По возрастанию</option>
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Применить
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ClientsFilterModal;
