import React, { useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_ROUTES } from "../settings/api";
import LoadingButton from "../components/LoadingButton";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const CreateGlass = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API_ROUTES.createGlass, data)
      .then((res) => {
        setError(null);
        setIsPending(false);
        navigate(-1);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  return (
    <Container>
      <div className="p-3 my-5 border shadow-lg rounded">
        <Form onSubmit={handleSubmit}>
          <h4 className="mb-3 pb-1 border-bottom">Добавить Стекло</h4>
          <Row className="justify-content-around">
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Толщина (мм)</Form.Label>
              <Form.Control
                type="number"
                required
                step={0.01}
                onChange={(e) =>
                  setData({ ...data, thickness: e.target.value })
                }
                placeholder="Толщина стекла"
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Цвет</Form.Label>
              <Form.Control
                required
                onChange={(e) => setData({ ...data, color: e.target.value })}
                type="text"
                placeholder='Например: "Qora"'
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Ширина Листа (мм)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Ширина Листа"
                onChange={(e) => setData({ ...data, width: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Высота Листа (мм)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Высота Листа"
                onChange={(e) => setData({ ...data, height: e.target.value })}
              />
            </Form.Group>
            <Form.Group as={Col} className="col-12 col-md-6 mb-3">
              <Form.Label>Количество (шт)</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Количество листов"
                onChange={(e) => setData({ ...data, quantity: e.target.value })}
              />
            </Form.Group>
          </Row>
          {error && <p className="text-danger">{error}</p>}
          <p className="text-end">
            {isPending ? (
              <LoadingButton />
            ) : (
              <button type="submit" className="btn btn-primary">
                Создать
              </button>
            )}
          </p>
        </Form>
      </div>
    </Container>
  );
};

export default CreateGlass;
