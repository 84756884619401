import React from "react";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddDrawings from "../components/CreateOrder/AddDrawings";
import CreateOrderForm from "../components/CreateOrder/CreateOrderForm";
import OrderAccessory from "../components/CreateOrder/OrderAccessory";

const CreateOrder = () => {
  const [orderId, setOrderId] = useState();
  const [drawingsFinished, setDrawingsFinished] = useState(false);
  const navigate = useNavigate();

  const handleDrawingSuccess = () => {
    setDrawingsFinished(true);
  };

  const handleAccessoriesSuccess = () => {
    navigate("/orders", { replace: true });
  };

  return (
    <div className="СreateOrder pt-lg-3">
      <Container>
        {!orderId && (
          <div className="createOrder">
            <CreateOrderForm setOrderId={setOrderId} />
          </div>
        )}

        {orderId && !drawingsFinished && (
          <div className="p-3 my-5 border rounded">
            <h4 className="mb-3 pb-1 border-bottom">Чертежи</h4>
            <AddDrawings
              handleSuccess={handleDrawingSuccess}
              orderId={orderId}
            />
          </div>
        )}

        {orderId && drawingsFinished && (
          <>
            <div className="p-3 my-5 border rounded">
              <h4 className="mb-3 pb-1 border-bottom">Аксессуары</h4>
              <OrderAccessory
                orderId={orderId}
                handleSuccess={handleAccessoriesSuccess}
              />
            </div>
            <div className="my-2 p-1">
              <button
                className="btn btn-success"
                onClick={handleAccessoriesSuccess}
              >
                Завершить
              </button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default CreateOrder;
