import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { API_ROUTES } from "../../settings/api";
import { useState } from "react";
import LoadingButton from "../LoadingButton";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const UpdatePaymentForm = ({ payment, handleSuccess }) => {
  const [amount, setAmount] = useState(payment.amount);
  const [comment, setComment] = useState(payment.comment);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const axios = useAxiosPrivate();

  const formData = () => {
    let data = { amount: amount };
    if (comment) {
      data.comment = comment;
    }
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = formData();
    axios
      .put(API_ROUTES.updatePayment(payment.id), data)
      .then((res) => {
        setError(null);
        setIsLoading(false);
        handleSuccess(res.data);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Form className="my-3" onSubmit={(e) => handleSubmit(e)}>
      <InputGroup className="mb-3">
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          aria-label="Сумма"
          type="number"
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Введите сумму"
          required
          value={amount}
          step={0.01}
        />
        <Form.Control
          aria-label="Комментарий"
          type="text"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Коммментарий"
          value={comment}
        />
        {isLoading ? (
          <LoadingButton />
        ) : (
          <button type="submit" className="btn btn-primary">
            Добавить
          </button>
        )}
      </InputGroup>
      {error && <p className="text-danger">{error}</p>}
    </Form>
  );
};

export default UpdatePaymentForm;
