import React from "react";
import { API_ROUTES } from "../../settings/api";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";

const SelectAccessoryCategory = ({data, setData, defaultValue}) => {
  const {
    data: fetchedCategories,
    isPending,
    error,
  } = useFetch(API_ROUTES.accessoryCategories);

  const options = [];

  for (let i = 0; i < fetchedCategories?.length; i++) {
    let category = fetchedCategories[i];
    options.push({
      value: category.id,
      label: category.name,
    });
  }

  return (
    <>
      {isPending && <p className="text-muted">Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
      <Select
          defaultValue={defaultValue}
          options={options}
          onChange={(e) => setData({...data, category: e.value})}
        />
    </>
  );
};

export default SelectAccessoryCategory;
