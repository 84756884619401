import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import ClientsFilterModal from "./ClientsFilterModal";

const ClientSearch = ({ setSearch, setCreateModalShow, setSortBy, setOrderBy }) => {
  const [searchInput, setSearchInput] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [sortByInput, setSortByInput] = useState();
  const [orderByInput, setOrderByInput] = useState("descending");

  const onSubmit = (e) => {
    e.preventDefault();
    setSearch(searchInput);
    setSortBy(sortByInput);
    setOrderBy(orderByInput);
    setFilterShow(false);
  };

  return (
    <div className="py-3 container-fluid">
      <div className="row">
        <div className="col-8 col-md-3">
          <Form onSubmit={onSubmit}>
            <InputGroup>
              <button type="submit" className="btn bg-starglass">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
              <Form.Control
                type="search"
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Поиск"
                value={searchInput}
              />
            </InputGroup>
          </Form>
        </div>
        <div className="col-4 col-md-9">
          <button
            className="btn btn-primary float-end me-2"
            onClick={() => setCreateModalShow(true)}
          >
            Добавить
          </button>
          <button
            className="btn bg-starglass float-end me-2"
            onClick={() => setFilterShow(true)}
          >
            <i class="fa-solid fa-filter"></i>
          </button>
          {searchInput && (
            <button
              className="btn btn-danger float-end me-2"
              onClick={() => setSearch("")}
            >
              Сбросить
            </button>
          )}
        </div>
      </div>
      
      <ClientsFilterModal handleSubmit={onSubmit} show={filterShow} setShow={setFilterShow} orderBy={orderByInput} setOrderBy={setOrderByInput} setSortBy={setSortByInput} sortBy={sortByInput} />
    </div>
  );
};

export default ClientSearch;
