import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import AccessoryInfoCard from "../components/Inventory/AccessoryInfoCard";
import AccessoryIncomesTable from "../components/Inventory/AccessoryIncomesTable";
import AccessoryExpensesTable from "../components/Inventory/AccessoryExpensesTable";
import { Link } from "react-router-dom";

const AccessoryDetails = () => {
  const { id } = useParams();

  return (
    <Container fluid>
      <Row className="my-3 justify-content-around">
        <Col className="col-12 col-md-3 rounded p-0 my-2">
          <AccessoryInfoCard accessoryId={id} />
        </Col>
        <Col className="col-12 col-md-5 border rounded my-2" style={{maxHeight: "80vh", overflow: "scroll"}}>
          <div className="p-2 border-bottom row">
            <div className="col-8">
              <h4>Расходы</h4>
            </div>
          </div>
          <AccessoryExpensesTable accessoryId={id} />
        </Col>
        <Col className="col-12 col-md-3 border rounded my-2">
          <div className="p-2 border-bottom row">
            <div className="col-8">
              <h4>Приходы</h4>
            </div>
            <div className="col-4 text-end">
              <Link to={`/inventory/accessories/${id}/income`}>
                <h4>
                  <i class="fa-solid fa-square-plus"></i>
                </h4>
              </Link>
            </div>
          </div>
          <AccessoryIncomesTable accessoryId={id} />
        </Col>
      </Row>
    </Container>
  );
};

export default AccessoryDetails;
