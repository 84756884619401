import React, { useState } from "react";
import { Row, Col, Image, Modal, Button } from "react-bootstrap";
import { API_URL } from "../../settings/api";

const DrawingsList = ({ drawings, handleDelete }) => {
  const [deleteDrawingId, setDeleteDrawingId] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const handleDeleteButtonClick = (drawingId) => {
    setDeleteDrawingId(drawingId);
    setDeleteModalShow(true);
  }

  const handleApproveDeleteButtonClick = () => {
    handleDelete(deleteDrawingId);
    setDeleteModalShow(false);
  }

  return (
    <div className="py-4 w-100 border mb-3">
      <Row className="justify-content-center">
        {drawings.map((drawing, index) => (
          <Col key={index} md={2} className="position-relative">
            <button
              onClick={() => handleDeleteButtonClick(drawing.id)}
              className="btn position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-6"
            >
              <i className="fa-solid fa-trash-can"></i>
              <span className="visually-hidden">Удалить</span>
            </button>
            <Image
              className="order-image"
              src={API_URL + drawing.image}
              thumbnail
            />
          </Col>
        ))}
      </Row>
      <Modal show={deleteModalShow} centered onHide={() => setDeleteModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ВНИМАНИЕ!!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Вы уверены что хотите удалить чертеж? Дальнейшее восстановление невозможно.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
            Отменить
          </Button>
          <Button variant="danger" onClick={handleApproveDeleteButtonClick}>
            Да, удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DrawingsList;
