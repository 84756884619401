import React, { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import UpdateAccessoryForm from "../components/Inventory/UpdateAccessoryForm";
import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const UpdateAccessory = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const {
    data: accessory,
    isPending,
    error,
  } = useFetch(API_ROUTES.getAccessory(id));

  useEffect(() => {
    if (accessory) {
      const updatedData = {
        name: accessory.name,
        price: accessory.price,
        category: accessory.category,
      };
  
      if (accessory.color) {
        updatedData.color = accessory.color.id;
      }
  
      setData(updatedData);
    }
  }, [accessory]);

  const [updateError, setUpdateError] = useState(null);
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpdatePending(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("color", data.color);

    if (data?.category?.name) {
      formData.append("category", data.category.id);
    } else {
      formData.append("category", data.category);
    }

    if (data.image) {
      formData.append("image", data.image);
    }
    if (data.color) {
      formData.append("color", data.color);
    }
    axios
      .put(API_ROUTES.updateAccessory(id), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUpdateError(null);
        setIsUpdatePending(false);
        navigate(-1);
      })
      .catch((error) => {
        setUpdateError(error.message);
        setIsUpdatePending(false);
      });
  };

  return (
    <Container>
      <div className="p-3 my-5 border shadow-lg rounded">
        <h4 className="mb-3 pb-1 border-bottom">Добавить Аксессуар</h4>
        {data && (
          <UpdateAccessoryForm
            handleSubmit={handleSubmit}
            accessory={accessory}
            data={data}
            setData={setData}
            error={updateError}
            isPending={isUpdatePending}
          />
        )}
        {isPending && <p className="text-muted">Загрузка формы...</p>}
        {error && <p className="text-danger">{error}</p>}
      </div>
    </Container>
  );
};

export default UpdateAccessory;
