import React, { useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import LoadingButton from "../LoadingButton";
import { API_ROUTES } from "../../settings/api";
import SelectGlassType from "../CreateOrder/SelectGlassType";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useFetch from "../../hooks/useFetch";
import SelectClient from "../CreateOrder/SelectClient";

const UpdateOrderForm = ({ id, handleSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const {
    data: order,
    isPending,
    error,
  } = useFetch(API_ROUTES.orderDetails(id));

  const [data, setData] = useState();

  useEffect(() => {
    if (order) {
      order.glass_dict = order.glass;
      order.glass = order.glass ? order.glass.id : null;
      order.client_dict = order.client;
      order.client = order.client.id;
    }
    setData(order);
  }, [order]);

  const axios = useAxiosPrivate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(API_ROUTES.orderUpdate(id), data)
      .then((res) => {
        setUpdateError(null);
        setIsLoading(false);
        handleSuccess();
      })
      .catch((error) => {
        setUpdateError(error.message);
        setIsLoading(false);
      });
  };

  return (
    <Form name="order" onSubmit={(e) => handleSubmit(e)}>
      {data && (
        <>        
          <SelectClient
            setClient={(id) => setData({ ...data, client: id })}
            defaultValue={{
              value: data.client_dict.id,
              label: `${data.client_dict.name} (${data.client_dict.phone_number})`,
            }}
          />
          <div className="p-3 my-3 border shadow-lg rounded">
            <h4 className="mb-3 pb-1 border-bottom">
              Изменить заказ №{data.order_number}
            </h4>
            <Row className="justify-content-around">
              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                {data.glass ? (
                  <SelectGlassType
                    setGlass={(id) => setData({ ...data, glass: id })}
                    defaultValue={{
                      value: data?.glass,
                      label: `${data?.glass_dict?.thickness}mm ${data?.glass_dict?.color}`,
                    }}
                  />
                ) : (
                  <SelectGlassType
                    setGlass={(id) => setData({ ...data, glass: id })}
                  />
                )}
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Квадратура</Form.Label>
                <Form.Control
                  onChange={(e) => setData({ ...data, area: e.target.value })}
                  type="number"
                  step={0.01}
                  placeholder="Кв.м."
                  value={data.area}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Количество изделий</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, quantity: e.target.value })
                  }
                  type="number"
                  placeholder="Количество (шт)"
                  value={data.quantity}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Срок</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, due_date: e.target.value })
                  }
                  type="date"
                  value={data.due_date}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Стекло USD</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, glass_price: e.target.value })
                  }
                  type="number"
                  placeholder="Сумма стекла"
                  value={data.glass_price}
                  step={0.01}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Закалка USD</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, tempering_price: e.target.value })
                  }
                  type="number"
                  placeholder="Сумма закалки"
                  value={data.tempering_price}
                  step={0.01}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Доставка USD</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, delivery_price: e.target.value })
                  }
                  type="number"
                  placeholder="Сумма доставки"
                  value={data.delivery_price}
                  step={0.01}
                />
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Статус</Form.Label>
                <Form.Select
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  defaultValue={data.status}
                >
                  <option value={1}>Ожидание</option>
                  <option value={2}>Произв-во</option>
                  <option value={3}>Готов</option>
                  <option value={4}>Выполнен</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
                <Form.Label>Примечание</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setData({ ...data, comment: e.target.value })
                  }
                  type="text"
                  placeholder="Комментарий"
                  value={data.comment}
                />
              </Form.Group>
              {(error || updateError) && <p className="text-danger">{error}</p>}
            </Row>
            <div className="text-end mt-2">
              {isPending || isLoading ? (
                <LoadingButton />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Далее
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default UpdateOrderForm;
