import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import LoadingButton from "../LoadingButton";
import { API_ROUTES } from "../../settings/api";
import SelectClient from "./SelectClient";
import SelectGlassType from "./SelectGlassType";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

const CreateOrderForm = ({setOrderId}) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState();
  const [client, setClient] = useState();
  const [glass, setGlass] = useState();
  const [area, setArea] = useState();
  const [quantity, setQuantity] = useState();
  const [due_date, setDue] = useState();
  const [glass_price, setGlassPrice] = useState(null);
  const [tempering_price, setTemperingPrice] = useState(null);
  const [delivery_price, setDeliveryPrice] = useState(null);
  const [status, setStatus] = useState(1);
  const [payment, setPayment] = useState(null);
  const [comment, setComment] = useState();

  const { isUserBoss } = useAuth();

  const axios = useAxiosPrivate();

  const formData = () => {
    const response = {
      client,
      glass,
      area,
      quantity,
      due_date,
      glass_price: glass_price || null,
      tempering_price: tempering_price || null,
      delivery_price: delivery_price || null,
      status,
      comment,
    };
    return response;
  };

  const addPayment = (orderId) => {
    axios.post(API_ROUTES.addPayment(orderId), { amount: payment }); // Make request to add payment
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .post(API_ROUTES.createOrder, formData())
      .then((res) => {
        const orderId = res.data.id;
        setOrderId(res.data.id);
        if (orderId && payment > 0) {
          addPayment(orderId); // check if there is payment and make a request
        }
        setError(null);
        setIsPending(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };
  return (
    <Form name="order" onSubmit={(e) => handleSubmit(e)}>
      <SelectClient setClient={setClient} />
      <div className="p-3 my-3 border shadow-lg rounded">
        <h4 className="mb-3 pb-1 border-bottom">Заказ</h4>
        <Row className="justify-content-around">
          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <SelectGlassType setGlass={setGlass} />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Квадратура</Form.Label>
            <Form.Control
              onChange={(e) => setArea(e.target.value)}
              type="number"
              step={0.01}
              placeholder="Кв.м."
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Количество изделий</Form.Label>
            <Form.Control
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
              placeholder="Количество (шт)"
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Срок</Form.Label>
            <Form.Control
              onChange={(e) => setDue(e.target.value)}
              type="date"
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Стекло USD</Form.Label>
            <Form.Control
              onChange={(e) => setGlassPrice(e.target.value)}
              type="number"
              placeholder="Сумма стекла"
              step={0.01}
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Закалка USD</Form.Label>
            <Form.Control
              onChange={(e) => setTemperingPrice(e.target.value)}
              type="number"
              placeholder="Сумма закалки"
              step={0.01}
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Доставка USD</Form.Label>
            <Form.Control
              onChange={(e) => setDeliveryPrice(e.target.value)}
              type="number"
              placeholder="Сумма доставки"
              step={0.01}
            />
          </Form.Group>

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Статус</Form.Label>
            <Form.Select
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={status}
            >
              <option value={1}>Ожидание</option>
              <option value={2}>Произв-во</option>
              <option value={3}>Готов</option>
              <option value={4}>Выполнен</option>
            </Form.Select>
          </Form.Group>

          {isUserBoss &&
          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Предоплата</Form.Label>
            <Form.Control
              onChange={(e) => setPayment(e.target.value)}
              type="number"
              placeholder="Предоплата"
            />
          </Form.Group>
          }

          <Form.Group as={Col} className="col-12 col-md-6 col-lg-4 mb-3">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              onChange={(e) => setComment(e.target.value)}
              type="text"
              placeholder="Комментарий"
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
        </Row>
        <div className="text-end mt-2">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Далее
            </button>
          )}
        </div>
      </div>
    </Form>
  );
};

export default CreateOrderForm;
