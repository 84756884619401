import React from "react";
import { Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_ROUTES } from "../../settings/api";
import { formatDateTime } from "../../settings/dateTime";
import empty from "../../images/noincomes.png";

const AccessoryIncomesTable = ({ accessoryId }) => {
  const {
    data: incomes,
    isPending,
    error,
  } = useFetch(API_ROUTES.getAccessoryIncomes(accessoryId));

  return (
    <>
      {incomes?.length > 0 ? (
        <Table className="p-0 m-0" striped hover>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Цена закуп.</th>
              <th>Кол-во</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {incomes.map((income) => (
              <tr key={income.id}>
                <td>{formatDateTime(income.date)}</td>
                <td>$ {income.purchase_price}</td>
                <td>{income.quantity} шт</td>
                <td>
                  <Link
                    className="text-secondary"
                    to={`/inventory/accessories/incomes/${income.id}/update`}
                  >
                    <i className="fa-solid fa-file-pen"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="empty-today-orders">
          <Image width={"100px"} src={empty}></Image>
          <p className="text-secondary">Записей нет!</p>
        </div>
      )}
      {isPending && <p>Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
    </>
  );
};

export default AccessoryIncomesTable;
