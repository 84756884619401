import React from "react";
import { API_ROUTES } from "../../settings/api";
import Select from "react-select";
import useFetch from "../../hooks/useFetch";

const SelectAccessoryCategoryMultiple = ({setCategories, selectedCategoryOptions, setSelectedCategoryOptions}) => {
  const {
    data: fetchedCategories,
    isPending,
    error,
  } = useFetch(API_ROUTES.accessoryCategories);

  const options = [];

  for (let i = 0; i < fetchedCategories?.length; i++) {
    let category = fetchedCategories[i];
    options.push({
      value: category.id,
      label: category.name,
    });
  }

  const handleChange = (selectedOptions) => {
    setSelectedCategoryOptions(selectedOptions);
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    setCategories(selectedValues);
  };

  return (
    <>
      {isPending && <p className="text-muted">Загрузка...</p>}
      {error && <p className="text-danger">{error}</p>}
        <Select
          isMulti
          options={options}
          onChange={handleChange}
          value={selectedCategoryOptions}
        />
    </>
  );
};

export default SelectAccessoryCategoryMultiple;
