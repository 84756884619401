import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate"

const useFetch = (url, fetchKey) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const axios = useAxiosPrivate();

    useEffect(() => {
        axios
          .get(url)
          .then((res) => {
            const response = res.data;
            setData(response);
            setIsPending(false);
          })
          .catch((error) => {
            setError(error.message);
            setIsPending(false);
          })
      }, [url, fetchKey]);
    
    return {data, isPending, error};
}

export default useFetch;