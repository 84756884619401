import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import noimage from "../../images/noimage.png";

const OrderAccessoryList = ({ accessories, deleteButton, handleDeleteClick }) => {
  return (
    <>
      {accessories.map((accessory) => (
        <div className="col-12 col-md-3 my-2">
          <Card key={accessory.id}>
            <Card.Img
              variant="top"
              height={300}
              className="w-100"
              src={
                accessory?.accessory?.image ? accessory.accessory.image : noimage
              }
            />
            <Card.Body>
              <Card.Title>{accessory?.accessory?.name ? `${accessory?.accessory?.name} ${accessory?.accessory?.color?.name}` : "Неизвестн"}</Card.Title>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>Комментарий: {accessory.comment || "-"}</ListGroup.Item>
                <ListGroup.Item>Кол-во: {accessory.quantity} шт</ListGroup.Item>
                <ListGroup.Item>Цена: $ {accessory.price}</ListGroup.Item>
                <ListGroup.Item><b>Сумма: $ {accessory.amount}</b></ListGroup.Item>
              </ListGroup>
              {deleteButton &&
              <div className="text-center">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteClick(accessory.id)}
                >
                  Удалить
                </button>
              </div>}
            </Card.Body>
          </Card>
        </div>
      ))}
    </>
  );
};

export default OrderAccessoryList;
