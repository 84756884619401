import React from "react";
import { Card, Image, Row, ListGroup, Dropdown } from "react-bootstrap";
import noImage from "../../images/noimage.png";
import { Link } from "react-router-dom";

const AccessoriesCards = ({ accessories }) => {
  return (
    <Row className="justify-content-around">
      {accessories.map((accessory) => (
        <Card className="text-center col-11 col-md-3 col-lg-2 m-3">
          <>
            <div className="text-center border-bottom p-3">
              {accessory.image ? (
                <Image width={100} height={100} src={accessory?.image} />
              ) : (
                <Image width={100} height={100} src={noImage} />
              )}
            </div>
            <Card.Body>
              <h5>{accessory.name}</h5>
              <ListGroup>
                <ListGroup.Item
                  style={
                    accessory.color
                      ? { backgroundColor: `#${accessory.color.hex_value}` }
                      : { backgroundColor: "#fff" }
                  }
                ></ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Ценник:</span>
                  <span>$ {accessory.price}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Закупка:</span>
                  <span>$ {accessory.purchase_price}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-between">
                  <span>Количество:</span>
                  {accessory.quantity <= 10 ? (
                    <span className="text-danger">{accessory.quantity} шт</span>
                  ) : (
                    <span>{accessory.quantity} шт</span>
                  )}
                </ListGroup.Item>
                <ListGroup.Item className="text-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="" className="py-0">
                      <i class="fa-solid fa-ellipsis"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link
                          className="dropdown-item text-primary"
                          to={`/inventory/accessories/${accessory.id}`}
                        >
                          <i class="fa-solid fa-table-list"></i>
                          <span className="ms-2">Детали</span>
                        </Link>
                      </Dropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <Dropdown.Item>
                        <Link
                          className="dropdown-item text-success"
                          to={`/inventory/accessories/${accessory.id}/income`}
                        >
                          <i class="fa-solid fa-square-plus"></i>
                          <span className="ms-2">Приход</span>
                        </Link>
                      </Dropdown.Item>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <Dropdown.Item>
                        <Link
                          className="dropdown-item text-secondary"
                          to={`/inventory/accessories/${accessory.id}/update`}
                        >
                          <i className="fa-solid fa-file-pen"></i>
                          <span className="ms-2">Изменить</span>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </>
        </Card>
      ))}
    </Row>
  );
};

export default AccessoriesCards;
