import React, { useState } from "react";
import { Navbar, Container, Offcanvas, Dropdown } from "react-bootstrap";
import user from "../../images/user.png";
import Links from "./Links";
import logo from "../../images/logo.png";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

const AppNavbar = ({ pageTitle }) => {
  const [sidebarShow, setSidebarShow] = useState(false);
  const toggleSidebarShow = () => setSidebarShow(!sidebarShow);
  const handleClose = () => setSidebarShow(false);
  const logout = useLogout();
  const navigate = useNavigate();

  const { tokenData } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <Navbar className="bg-starglass py-3" sticky="top" variant="dark">
      <Container>
        <Navbar.Brand>
          <span onClick={toggleSidebarShow} className="cursor-pointer">
            <i className="fa-solid fa-bars fs-4"></i>
          </span>
          <Link to={"/"} className="text-decoration-none fw-bold ms-4 text-light">
            STAR GLASS
          </Link>
        </Navbar.Brand>

        <div className="justify-content-end">
          <Dropdown>
            <Dropdown.Toggle variant="" className="py-0 text-light">
              <div className="user-button rounded-pill">
                <img src={user} width={40} alt="" />
                <span className="ms-3 me-4">{tokenData.name}</span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
              <Dropdown.Item>
                <Link
                  className="dropdown-item text-primary text-dark"
                  to={`/user/change-password`}
                >
                  Изменить Пароль
                </Link>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLogout} className="text-danger">
                Выйти
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>

      <Offcanvas
        show={sidebarShow}
        onHide={handleClose}
        scroll={true}
        backdrop={true}
        data-bs-theme="dark"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={logo} width={75} alt="" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Links />
        </Offcanvas.Body>
      </Offcanvas>
    </Navbar>
  );
};

export default AppNavbar;
