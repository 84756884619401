import useAuth from "../hooks/useAuth";
import { Outlet } from "react-router-dom";


const ReaderGuard = ({ children }) => {
  const { isUserReader
    
   } = useAuth();

  if (isUserReader) {
    return <div>Доступ Запрещен</div>;
  }

  return <Outlet />;
};

export default ReaderGuard;