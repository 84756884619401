import React from "react";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES } from "../../settings/api";

const SelectColor = ({ data, setData }) => {
  const axios = useAxiosPrivate();
  const [colors, setColors] = useState([]);

  useEffect(() => {
    async function fetchColors() {
      try {
        const response = await axios.get(API_ROUTES.accessoryColors);
        setColors(response.data);
      } catch (error) {
        console.error("Failed to fetch colors:", error);
      }
    }
    fetchColors();
  }, [axios]);

  return (
    <div>
      <select
        value={data?.color}
        className="form-select"
        onChange={(e) => setData({ ...data, color: e.target.value })}
      >
        <option value="">Нет цвета</option>
        {colors.map((color) => (
          <option key={color.id} value={color.id}>
            {color.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectColor;
