import React from "react";
import SelectDrawings from "./SelectDrawings";
import { useState } from "react";
import { Form, Col } from "react-bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES } from "../../settings/api";
import LoadingButton from "../LoadingButton";

const AddDrawings = ({ orderId, handleSuccess }) => {
  const [drawings, setDrawings] = useState();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const axios = useAxiosPrivate();

  const formData = new FormData();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);

    drawings.forEach((file) => {
      formData.append("image", file);
    });

    axios
      .post(API_ROUTES.addDrawings(orderId), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setIsPending(false);
        handleSuccess();
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} className="col-12">
          <SelectDrawings setFiles={setDrawings} />
        </Form.Group>
        {error && <p className="text-danger">{error}</p>}
        {isPending ? (
          <LoadingButton />
        ) : (
          <div>
            {drawings &&
            <button type="submit" className="btn btn-primary">
              Добавить
            </button> 
            }
            <button type="button" href="#" className="btn btn-secondary ms-2" onClick={handleSuccess}>
            {!drawings ? "Далее" : "Пропустить"}
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default AddDrawings;
