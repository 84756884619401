import React, { useState } from "react";
import { API_ROUTES } from "../settings/api";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import LoadingButton from "../components/LoadingButton";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const PasswordChangeForm = () => {
  const axios = useAxiosPrivate();
  const [inputs, setInputs] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);
    if (inputs.newPassword !== inputs.confirmPassword) {
      setMessage("Новые пароли не совпадают!");
      setIsPending(false);
      return;
    }

    try {
      await axios.put(API_ROUTES.changePassword, {
        old_password: inputs.oldPassword,
        new_password: inputs.newPassword
      });
      setIsPending(false);
      await logout();
      navigate("/login");
    } catch (error) {
      setMessage('Не удалось поменять пароль. Проверьте введенные данные!');
      setIsPending(false);
    }
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <Form
        onSubmit={handleSubmit}
        className="col-12 col-md-4 border rounded shadow-lg p-5"
      >
        <h2 className="text-center mb-4">Изменить Пароль</h2>
        <div className="mb-3">
          <label htmlFor="oldPassword" className="form-label">
            Текущий Пароль
          </label>
          <Form.Control
            type="password"
            id="oldPassword"
            name="oldPassword"
            value={inputs.oldPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">
            Новый Пароль
          </label>
          <Form.Control
            type="password"
            id="newPassword"
            name="newPassword"
            value={inputs.newPassword}
            onChange={handleChange}
            required
          />
          <p className="text-muted">
            Пароль должен содержать как минимум 8 символов и состоять из букв и
            цифр.
          </p>
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Подтвердите Новый Пароль
          </label>
          <Form.Control
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={inputs.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="d-grid">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Готово
            </button>
          )}
        </div>
        {message && (
          <div className="alert alert-danger mt-3" role="alert">
            {message}
          </div>
        )}
      </Form>
    </div>
  );
};

export default PasswordChangeForm;
