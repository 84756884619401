import React from "react";
import noimage from "./images/no.png";
import { Card, Row, Col, Image } from "react-bootstrap";
import { API_URL } from "../../settings/api";
import { Link } from "react-router-dom";

const Drawings = ({ drawings, orderId }) => {
  return (
    <Card className="h-100 shadow">
      <Card.Header as="h4">
        <span className="me-2">Чертежи</span>
        <Link
          to={`/orders/${orderId}/update-drawings`}
          className="text-secondary"
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </Link>
      </Card.Header>
      <Card.Body className="text-center d-flex flex-column">
        {drawings && drawings.length > 0 ? (
          <Row className="flex-grow-1 justify-content-center align-items-center">
            {drawings.map((drawing, index) => (
              <Col key={index} md={drawings.length === 1 ? 12 : 6}>
                <Link to={API_URL + drawing.image} target="_blank" download={false}>
                <Image
                  className="order-image"
                  src={API_URL + drawing.image}
                  thumbnail
                />
                </Link>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="empty-today-orders">
            <Image width={"100px"} src={noimage}></Image>
            <p className="text-secondary">Чертежей нет!</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
export default Drawings;
