import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import { Container, Row } from "react-bootstrap";
import ClientInfo from "../components/ClientDetails/ClientInfo";
import OrdersTable from "../components/OrderList/OrdersTable";
import UpdateClientModal from "../components/ClientDetails/UpdateClientModal";
import useAuth from "../hooks/useAuth";
import ClientChart from "../components/ClientDetails/ClientChart";
import OrderPagination from "../components/OrderList/OrderPaginaiton";

const Client = () => {
  const { id } = useParams();
  const [fetchKey, setFetchKey] = useState(1);
  const { isUserBoss } = useAuth();

  const {
    data: client,
    isPending,
    error,
  } = useFetch(API_ROUTES.clientDetails(id), fetchKey);

  const [ordersPage, setOrdersPage] = useState(1);
  const [fetchOrdersUrl, setFetchOrdersUrl] = useState(`${API_ROUTES.getOrders}?client=${client?.id}&page=${ordersPage}`)

  useEffect(() => {
    setFetchOrdersUrl(`${API_ROUTES.getOrders}?client=${client?.id}&page=${ordersPage}`);
  }, [ordersPage, client])

  const {
    data: orders,
    isOrdersPending,
    ordersError,
  } = useFetch(fetchOrdersUrl);

  const [updateModalShow, setUpdateModalShow] = useState(false);

  const handleUpdate = () => {
    setFetchKey(fetchKey + 1);
  }

  return (
    <>
      <Container fluid className="py-3">
        <Row className="justify-content-center">
          <div className="col-12 col-md-3 my-0">
            <ClientInfo client={client} setUpdateModalShow={setUpdateModalShow} isUserBoss={isUserBoss} />
            {isUserBoss &&
            <UpdateClientModal show={updateModalShow} setShow={setUpdateModalShow} client={client} handleSuccess={handleUpdate} />}
            {isPending && <p>Загрузка...</p>}
            {error && <p className="text-danger">{error}</p>}
          </div>
          <div className="col-11 col-md-8 border rounded shadow-lg p-2">
              {client &&
              <ClientChart clientId={client?.id} />}
          </div>
          <div className="col-11 border rounded shadow my-3">
            <h4 className="py-2">Заказы {client?.name}</h4>
            {orders &&
            <OrdersTable orders={orders} withoutClient={true} />}
            {<OrderPagination page={ordersPage} setPage={setOrdersPage} next={orders?.next} previous={orders?.previous} count={orders?.count} />}
            {isOrdersPending && <p>Загрузка заказов...</p>}
            {ordersError && <p className="text-danger">{ordersError}</p>}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Client;
