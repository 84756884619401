import { createContext, useState } from "react";
import { useEffect } from "react";

const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [tokenData, setTokenData] = useState({});
    const [isUserBoss, setIsUserBoss] = useState(false);
    const [isUserReader, setIsUserReader] = useState(false);

    useEffect(() => {
        if (tokenData) {
          setIsUserBoss(tokenData.is_boss || false);
          setIsUserReader(tokenData.is_reader || false);
        }
      }, [tokenData]);

    return (
        <AuthContext.Provider value={{auth, setAuth, tokenData, setTokenData, isUserBoss, setIsUserBoss, isUserReader, setIsUserReader}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
