import React, { useState } from "react";
import AccessoriesToChoose from "./AccessoriesToChoose";
import ChosenAccessories from "./ChosenAccessories";
import { Form } from "react-bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES } from "../../settings/api";
import LoadingButton from "../LoadingButton";

const OrderAccessory = ({ handleSuccess, orderId }) => {
  const [chosenAccessories, setChosenAccessories] = useState([]);
  const [data, setData] = useState([]);
  const [chooseShow, setChooseShow] = useState(false);
  const axios = useAxiosPrivate();
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const handleChoose = (accessory) => {
    setChosenAccessories([...chosenAccessories, accessory]);
    setData([
      ...data,
      {
        accessory: accessory.id,
        quantity: null,
        price: accessory.price,
        comment: null,
      },
    ]);
  };

  const handleUnChoose = (accessory) => {
    setChosenAccessories(
      chosenAccessories.filter((item) => item !== accessory)
    );
    setData(data.filter((item) => item.accessory !== accessory.id));
  };

  const handleInputChange = (accessoryId, field, value) => {
    setData((prev) =>
      prev.map((acc) =>
        acc.accessory === accessoryId ? { ...acc, [field]: value } : acc
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API_ROUTES.createOrderAccessory(orderId), data)
      .then((res) => {
        setError(null);
        setIsPending(false);
        handleSuccess();
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  return (
    <>
      <div className="py-2">
        {chosenAccessories && (
          <>
            {chosenAccessories.length > 0 ? (
              <>
                <Form onSubmit={handleSubmit}>
                  <ChosenAccessories
                    accessories={chosenAccessories}
                    handleUnChoose={handleUnChoose}
                    setShow={setChooseShow}
                    handleInputChange={handleInputChange}
                  />
                  <button
                    className="btn btn-primary me-2"
                    type="button"
                    onClick={() => setChooseShow(true)}
                  >
                    <i class="fa-solid fa-table-cells"></i>
                  </button>
                  {isPending ? (
                    <LoadingButton />
                  ) : (
                    <button className="btn btn-primary" type="submit">
                      Добавить и завершить
                    </button>
                  )}
                  {error && <p className="text-danger">{error}</p>}
                </Form>
              </>
            ) : (
              <div className="empty-today-orders">
                <button
                  className="btn btn-primary fs-1"
                  onClick={() => setChooseShow(!chooseShow)}
                >
                  <i class="fa-solid fa-cart-plus"></i>
                </button>
                <p className="text-secondary">Вы еще не выбрали аксессуары</p>
              </div>
            )}
          </>
        )}
      </div>
      <AccessoriesToChoose
        handleChoose={handleChoose}
        handleUnChoose={handleUnChoose}
        chosenAccessories={chosenAccessories}
        show={chooseShow}
        setShow={setChooseShow}
      />
    </>
  );
};

export default OrderAccessory;
