import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import { API_ROUTES } from "../settings/api";
import AccessoryIncomeForm from "../components/Inventory/AccessoryIncomeForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

const UpdateAccessoryIncome = () => {
    const {id} = useParams();
    const axios = useAxiosPrivate();
    const navigate = useNavigate();
    const {data: income, isPending, error} = useFetch(API_ROUTES.getAccessoryIncome(id))
    const [data, setData] = useState();
    const [isUpdatePending, setIsUpdatePending] = useState(false);
    const [updateError, setUpdateError] = useState();

    useEffect(() => {
        setData({quantity: income?.quantity, purchase_price: income?.purchase_price})
    }, [income])

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsUpdatePending(true);
        const formData = new FormData();
        formData.append("quantity", data.quantity);
        formData.append("purchase_price", data.purchase_price);
    
        axios
          .put(API_ROUTES.updateAccessoryIncome(id), formData)
          .then((res) => {
            setUpdateError(null);
            setIsUpdatePending(false);
            navigate(-1);
          })
          .catch((error) => {
            setUpdateError(error.message);
            setIsUpdatePending(false);
          });
      };

    return (
        <div className="py-2">
          <Container>
            {income && (
              <div className="p-3 my-5 border shadow-lg rounded">
                <div className="mb-3 pb-1 border-bottom row align-items-center">
                  <div className="col-6">
                    <h4>Изменить приход</h4>
                  </div>
                </div>
                <AccessoryIncomeForm
                  handleSubmit={handleSubmit}
                  data={data}
                  setData={setData}
                  isPending={isUpdatePending}
                  error={updateError}
                />
              </div>
            )}
            {isPending && <p>Загрузка...</p>}
            {error && <p className="text-danger">{error}</p>}
          </Container>
        </div>
      );
}

export default UpdateAccessoryIncome;