import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { formatDateTime, formatDate } from "../../settings/dateTime";
import { Link } from "react-router-dom";


const Info = (props) => {
  const order = props.order;

  return (
    <Card className="h-100 shadow">
      <Card.Header as="h4">
        <span className="me-2">Заказ №{order.order_number}</span>
        <Link
          to={`/orders/${order.id}/update`}
          className="text-secondary"
        >
          <i className="fa-solid fa-pen-to-square"></i>
        </Link>
      </Card.Header>
      <Card.Body>
        <ListGroup>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Клиент:</span>
            <span>{order.client.name}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Стекло:</span>
            <span>{`${order?.glass?.thickness}mm ${order?.glass?.color}` || "-"}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Квадратура:</span>
            <span>
              {order.area} м<sup>2</sup>
            </span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Количество:</span>
            <span>{order.quantity} шт</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Срок:</span>
            <span>{formatDate(order.due_date)}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Стекло:</span>
            <span>$ {order.glass_price | 0}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Закалка:</span>
            <span>$ {order.tempering_price | 0}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Доставка:</span>
            <span>$ {order.delivery_price | 0}</span>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Аксессуары:</span>
            <span>$ {order.accessories_price | 0}</span>
          </ListGroup.Item>          
          <ListGroup.Item className="d-flex justify-content-between">
            <span>Сумма:</span>
            <span className="fw-bold">$ {order.amount}</span>
          </ListGroup.Item>
          {order.ready_date && (
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Готов:</span>
              <span>{formatDateTime(order.ready_date)}</span>
            </ListGroup.Item>
          )}
          {order.load_date && (
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Отгружен:</span>
              <span>{formatDateTime(order.load_date)}</span>
            </ListGroup.Item>
          )}
          {order.comment && (
            <ListGroup.Item className="d-flex justify-content-between">
              <span>Примечание:</span>
              <span>{order.comment}</span>
            </ListGroup.Item>
          )}
          <ListGroup.Item className="text-center" disabled>{formatDateTime(order.date)} ({order.created_by.name})</ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default Info;
