import React from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import axios from "../settings/axios";
import { API_ROUTES } from "../settings/api";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import LoadingButton from "../components/LoadingButton";

const Login = () => {
  const { setAuth, setTokenData, setIsUserBoss, setIsUserReader } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [totpCode, setTotpCode] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    axios
      .post(
        API_ROUTES.login,
        { username, password, totp_code: totpCode },
        { withCredentials: true }
      )
      .then((response) => {
        const accessToken = response?.data?.access;
        const tokenData = jwtDecode(accessToken);
        setIsUserBoss(tokenData.is_boss);
        setIsUserReader(tokenData.is_reader);
        setAuth({ accessToken });
        setTokenData(tokenData);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        if (!error?.response) {
          setError("Сервер не отвечает. Обратитесь к администратору.");
          setIsPending(false);
        } else {
          setError(error.response?.data.detail);
          setIsPending(false);
        }
      });
  };

  return (
    <div
      className="login-page-container"
      style={{ height: "100vh" }}
    >
      <Form
        onSubmit={handleSubmit}
        className="col-12 col-md-4 p-5 rounded shadow form-container text-light"
      >
        <h2 className="text-center mb-4">Вход</h2>
        <Form.Group className="mb-3">
          <Form.Label>Имя Пользователя</Form.Label>
          <Form.Control
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            placeholder="Имя Пользователя"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="********"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Код 2ФА</Form.Label>
          <Form.Control
            onChange={(e) => setTotpCode(e.target.value)}
            type="text"
            maxLength={6}
            placeholder="XXXXXX"
            required
          />
        </Form.Group>
        <div className="d-grid">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Готово
            </button>
          )}
        </div>
        {error && (
          <div className="alert alert-danger mt-3" role="alert">
            {error}
          </div>
        )}
      </Form>
    </div>
  );
};

export default Login;
