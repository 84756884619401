import React from "react";
import useAuth from "../../hooks/useAuth";
import { Table, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { formatDateShort } from "../../settings/dateTime";

const TodayOrdersTable = ({ orders }) => {
  const { isUserBoss } = useAuth();

  return (
    <div className="border-top">
      <Table className="p-0 m-0" striped hover>
        <thead>
          <tr className="table-secondary">
            <th>{orders.count}</th>
            <th colSpan={2}></th>
            <th>{orders.total_area}</th>
            <th></th>
            <th>{orders.total_glass_price}</th>
            <th>{orders.total_tempering_price}</th>
            <th>{orders.total_accessories_price}</th>
            <th>{orders.total_delivery_price}</th>
            <th>{orders.total_amount}</th>
            <th></th>
          </tr>
          <tr>
            <th>№</th>
            <th>Клиент</th>
            <th>Стекло</th>
            <th>Кв.м</th>
            <th>Срок</th>
            <th>Стекло</th>
            <th>Закалка</th>
            <th>Аксесс</th>
            <th>Доставка</th>
            <th>Сумма</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders?.results.map((order) => (
            <tr key={order.id} className={order.deleted && "table-danger"}>
              <th scope="row">{order.order_number}</th>
              <td>{order.client.name}</td>
              <td>{order.glass || "-"}</td>
              <td>{order.area}</td>
              <td>{formatDateShort(order.due_date)}</td>
              <td className="fw-bold">$ {order.glass_price | 0}</td>
              <td className="fw-bold">$ {order.tempering_price | 0}</td>
              <td className="fw-bold">$ {order.accessories_price | 0}</td>
              <td className="fw-bold">$ {order.delivery_price | 0}</td>
              <td className="fw-bold">$ {order.amount}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="" className="py-0">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="text-center">
                    <Dropdown.Item>
                      <Link
                        className="dropdown-item text-primary"
                        to={`/orders/${order.id}`}
                      >
                        <i className="fa-solid fa-file-lines"></i>
                      </Link>
                    </Dropdown.Item>
                    {isUserBoss && (
                      <>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <Dropdown.Item>
                          <Link
                            className="dropdown-item text-secondary"
                            to={`/orders/${order.id}/update`}
                          >
                            <i className="fa-solid fa-file-pen"></i>
                          </Link>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TodayOrdersTable;
