import React from "react";
import { Form, InputGroup, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import SelectGlassType from "../CreateOrder/SelectGlassType";

const OrderFilters = ({ filters, setFilters }) => {
  const [search, setSearch] = useState(filters?.search || null);

  const [filtersShow, setFiltersShow] = useState();

  const [dateFrom, setDateFrom] = useState(filters?.date_from || null);
  const [dateTo, setDateTo] = useState(filters?.date_to || null);
  const [dueDateFrom, setDueDateFrom] = useState(
    filters?.due_date_from || null
  );
  const [dueDateTo, setDueDateTo] = useState(filters?.due_date_to || null);
  const [loadDateFrom, setLoadDateFrom] = useState(
    filters?.load_date_from || null
  );
  const [loadDateTo, setLoadDateTo] = useState(filters?.load_date_to || null);
  const [notPaid, setNotPaid] = useState(filters?.notPaid || null);
  const [status, setStatus] = useState(filters?.status || null);
  const [amountFrom, setAmountFrom] = useState(filters?.amount_from || null);
  const [amountTo, setAmountTo] = useState(filters?.amount_to || null);
  const [glasses, setGlasses] = useState(filters?.glasses || null);

  const [selectedGlassOptions, setSelectedGlassOptions] = useState(); // TO DISPLAY CHOSEN GLASS OPTIONS

  const handleFilter = (e) => {
    e.preventDefault();
    setFiltersShow(false);
    let data = {
      search,
      glasses,
      date_from: dateFrom,
      date_to: dateTo,
      due_date_from: dueDateFrom,
      due_date_to: dueDateTo,
      load_date_from: loadDateFrom,
      load_date_to: loadDateTo,
      not_paid: notPaid === true ? notPaid : null,
      amount_from: amountFrom,
      amount_to: amountTo,
      status,
    };
    return setFilters(data);
  };

  const handleFiltersShow = () => setFiltersShow(true);

  const resetFilter = () => {
    setSearch("");
    setDateFrom(null);
    setDateTo(null);
    setDueDateFrom(null);
    setDueDateTo(null);
    setLoadDateFrom(null);
    setLoadDateTo(null);
    setNotPaid(null);
    setStatus(null);
    setAmountFrom(null);
    setAmountTo(null);
    setGlasses(null);
    setFilters(null);
    setFiltersShow(false);
    localStorage.removeItem("ordersFilters");
    localStorage.removeItem("currentPage");
  };

  return (
    <div className="py-3 container-fluid">
      <div className="row">
        <div className="col-8 col-md-3">
          <Form onSubmit={(e) => handleFilter(e)}>
            <InputGroup>
              <button type="submit" className="btn bg-starglass">
                <i className="fa-solid fa-magnifying-glass"></i>
              </button>
              <Form.Control
                type="search"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Поиск"
                value={search}
              />
            </InputGroup>
          </Form>
        </div>
        <div className="col-4 col-md-9">
          <button
            onClick={handleFiltersShow}
            className="btn bg-starglass float-end"
          >
            <i className="fa-solid fa-filter"></i>
          </button>
          {filters && (
            <button
              className="btn btn-danger float-end me-2"
              onClick={resetFilter}
            >
              Сбросить
            </button>
          )}

          <Modal
            show={filtersShow}
            onHide={() => setFiltersShow(false)}
            centered
            size="xl"
          >
            <Form onSubmit={(e) => handleFilter(e)}>
              <Modal.Header closeButton>
                <Modal.Title>Фильтр</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h5>Дата заказа</h5>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-2 mb-md-0">
                      <InputGroup>
                        <InputGroup.Text>От</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setDateFrom(e.target.value)}
                          value={dateFrom}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-12 col-md-6">
                      <InputGroup>
                        <InputGroup.Text>До</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setDateTo(e.target.value)}
                          value={dateTo}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <h5>Срок</h5>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-2 mb-md-0">
                      <InputGroup>
                        <InputGroup.Text>От</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setDueDateFrom(e.target.value)}
                          value={dueDateFrom}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-12 col-md-6">
                      <InputGroup className="col-12 col-md-6">
                        <InputGroup.Text>До</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setDueDateTo(e.target.value)}
                          value={dueDateTo}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <h5>Отгрузка</h5>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-2 mb-md-0">
                      <InputGroup>
                        <InputGroup.Text>От</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setLoadDateFrom(e.target.value)}
                          value={loadDateFrom}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-12 col-md-6">
                      <InputGroup className="col-12 col-md-6">
                        <InputGroup.Text>До</InputGroup.Text>
                        <Form.Control
                          type="date"
                          onChange={(e) => setLoadDateTo(e.target.value)}
                          value={loadDateTo}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <h5>Оплата</h5>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Не Оплачен"
                    checked={notPaid === true} // Ensure the switch is controlled
                    onChange={(e) => setNotPaid(e.target.checked ? true : null)} // Handle change
                  />
                </div>

                <div className="my-4">
                  <h5>Статус</h5>
                  <div className="row">
                    <Form.Group className="col-12">
                      <Form.Select
                        onChange={(e) => setStatus(e.target.value)}
                        defaultValue={null}
                        value={status}
                      >
                        <option value="">Все</option>
                        <option value={1}>Ожидание</option>
                        <option value={2}>Произв-во</option>
                        <option value={3}>Готов</option>
                        <option value={4}>Выполнен</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="my-4">
                  <h5>Сумма</h5>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-2 mb-md-0">
                      <InputGroup>
                        <InputGroup.Text>От</InputGroup.Text>
                        <Form.Control
                          placeholder="Сумма > X"
                          type="number"
                          onChange={(e) => setAmountFrom(e.target.value)}
                          value={amountFrom}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-12 col-md-6">
                      <InputGroup>
                        <InputGroup.Text>До</InputGroup.Text>
                        <Form.Control
                          placeholder="Сумма < X"
                          type="number"
                          onChange={(e) => setAmountTo(e.target.value)}
                          value={amountTo}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <h5>Стекло</h5>
                  <div className="row">
                    <div className="col-12 mb-2 mb-md-0">
                      <SelectGlassType
                        isMulti={true}
                        setMultipleGlasses={setGlasses}
                        selectedGlassOptions={selectedGlassOptions}
                        setSelectedGlassOptions={setSelectedGlassOptions}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {filters && (
                  <Button variant="danger" onClick={resetFilter} type="reset">
                    Сбросить
                  </Button>
                )}
                <Button variant="primary" type="submit">
                  Применить
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default OrderFilters;
