import React, { useState } from "react";
import { Dropdown, Toast } from "react-bootstrap";
import { STATUS_RU, STATUS_COLORS } from "../../settings/status";
import { API_ROUTES } from "../../settings/api";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Status = (props) => {
  const order = props.order;
  const [currentStatus, setCurrentStatus] = useState(order.status);
  const [newStatus, setNewStatus] = useState(order.status);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosPrivate
      .post(API_ROUTES.changeStatus(order.id), {
        status: newStatus,
      })
      .then((response) => {
        setCurrentStatus(response.data.status);
        setNewStatus(response.data.status);
        setShowSuccessToast(true);
      })
      .catch((error) => {
        console.error("Error changing status:", error);
        setShowErrorToast(true);
      });
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="" className="p-0 m-0">
          <span className={`badge bg-${STATUS_COLORS[currentStatus]}`}>
            {STATUS_RU[currentStatus]}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="text-center">
          <form action="" onSubmit={handleSubmit}>
            <select
              className="form-control mb-2"
              defaultValue={currentStatus}
              onChange={(choice) => setNewStatus(choice.target.value)}
            >
              {Object.keys(STATUS_RU).map((key, index) => {
                return (
                  <option key={index} value={key}>
                    {STATUS_RU[key]}
                  </option>
                );
              })}
            </select>
            <button type="submit" className="btn btn-sm btn-primary">
              Изменить
            </button>
          </form>
        </Dropdown.Menu>
      </Dropdown>
      {/* Bootstrap Success Toast */}
      <Toast show={showSuccessToast} onClose={() => setShowSuccessToast(false)} className="position-fixed bottom-0 end-0 m-3 z-1">
        <Toast.Header>
          <strong className="me-auto text-success">Статус изменен</strong>
        </Toast.Header>
        <Toast.Body>Статус заказа №{order.order_number} успешно изменен!</Toast.Body>
      </Toast>

      {/* Bootstrap Error Toast */}
      <Toast show={showErrorToast} onClose={() => setShowErrorToast(false)} className="position-fixed z-2 bottom-0 end-0 m-3">
        <Toast.Header>
          <strong className="me-auto text-danger">Ошибка</strong>
        </Toast.Header>
        <Toast.Body>Не удалось изменить статус, попробуйте снова!</Toast.Body>
      </Toast>
    </>
  );
};

export default Status;
