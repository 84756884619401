import React, { useState } from "react";
import { Modal, Toast, Button } from "react-bootstrap";
import { API_ROUTES } from "../../settings/api";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const OrderDeleteModal = ({ order, show, setShow, onDelete }) => {
  const axios = useAxiosPrivate();
  const [error, setError] = useState();
  const [showToast, setShowToast] = useState(false);

  const handleDelete = async () => {
    try {
      await axios.delete(API_ROUTES.deleteOrder(order.id));
      onDelete();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>ВНИМАНИЕ!!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Вы уверены что хотите удалить заказ №{order.order_number} и все данные о
          нем?
          {error && <p className="text-danger pt-3">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Да, удалить
          </Button>
          <Button variant="primary" onClick={() => setShow(false)}>
            Отменить
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Bootstrap Success Toast */}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        className="position-fixed bottom-0 end-0 m-3"
      >
        <Toast.Header>
          <strong className="me-auto text-danger">Заказ удален</strong>
        </Toast.Header>
        <Toast.Body>Заказ № {order.order_number} удален!</Toast.Body>
      </Toast>
    </>
  );
};

export default OrderDeleteModal;
