import React, { useState, useEffect } from "react";
import OrdersTable from "../components/OrderList/OrdersTable";
import OrderFilters from "../components/OrderList/OrderFilters";
import useFetch from "../hooks/useFetch";
import { API_ROUTES } from "../settings/api";
import OrderPagination from "../components/OrderList/OrderPaginaiton";

const Orders = () => {
  const defaultPage = JSON.parse(localStorage.getItem("currentPage")) || 1;
  const [page, setPage] = useState(defaultPage);
  const [fetchKey, setFetchKey] = useState(0);

  const getInitialFetchUrl = () => {
    const defaultPage = JSON.parse(localStorage.getItem("currentPage")) || 1;
    const savedFilters = JSON.parse(localStorage.getItem("ordersFilters"));
    
    if (savedFilters) {
      let queryParams = "";
      savedFilters?.glasses?.forEach((glassId) => {
        queryParams += `glass=${glassId}&`;
      });
      for (const [key, value] of Object.entries(savedFilters)) {
        if (key !== "glasses" && value) {
          queryParams += `${key}=${value}&`;
        }
      }
      return `${API_ROUTES.getOrders}?${queryParams}page=${defaultPage}`;
    } else {
      return `${API_ROUTES.getOrders}?page=${defaultPage}`;
    }
  };

  const [fetchUrl, setFetchUrl] = useState(getInitialFetchUrl());
  const { data: orders, isPending, error } = useFetch(fetchUrl, fetchKey);

  const saveFilters = (filters) => {
    localStorage.setItem("ordersFilters", JSON.stringify(filters));
  };

  const getFilters = () => {
    const savedFilters = localStorage.getItem("ordersFilters");
    return savedFilters ? JSON.parse(savedFilters) : null // Ensure a default is returned if null
  };

  const [filters, setFilters] = useState(getFilters());

  useEffect(() => {
    localStorage.setItem("currentPage", JSON.stringify(page));
  }, [page]);

  useEffect(() => {
    if (filters) {
      console.log(filters)
      saveFilters(filters);
      let query_params = "";
      filters?.glasses?.forEach((glassId) => {
        query_params += `glass=${glassId}&`;
      });
      for (const [key, value] of Object.entries(filters)) {
        if (key!=="glasses" && value) {
          query_params += `${key}=${value}&`;
        }
      }
      setFetchUrl(`${API_ROUTES.getOrders}?${query_params}page=${page}`);
    } else {
      setFetchUrl(`${API_ROUTES.getOrders}?page=${page}`);
    }
  }, [filters, page]);

  return (
    <div>
      <OrderFilters setFilters={setFilters} filters={filters} />
      {orders && <OrdersTable orders={orders} fetchKey={fetchKey} setFetchKey={setFetchKey} filters={filters} />}
      {orders && <OrderPagination page={page} setPage={setPage} next={orders.next} previous={orders.previous} count={orders.count} />}
      {isPending && <p className="text-secondary">Loading...</p>}
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default Orders;
