import React from "react";
import { Container, Image } from "react-bootstrap";
import AccessoryIncomeForm from "../components/Inventory/AccessoryIncomeForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { API_ROUTES } from "../settings/api";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";

const AccessoryIncome = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const [data, setData] = useState({});

  const {
    data: accessory,
    isPending: isAccessoryPending,
    error: accessoryError,
  } = useFetch(API_ROUTES.getAccessory(id));

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const formData = new FormData();
    formData.append("quantity", data.quantity);
    formData.append("purchase_price", data.purchase_price);

    axios
      .post(API_ROUTES.createAccessoryIncome(id), formData)
      .then((res) => {
        setError(null);
        setIsPending(false);
        navigate(-1);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  return (
    <div className="py-2">
      <Container>
        {accessory && (
          <div className="p-3 my-5 border shadow-lg rounded">
            <div className="mb-3 pb-1 border-bottom row align-items-center">
              <div className="col-6">
                <h4>Приход {accessory.name}</h4>
                <p className="my-1">Цвет: {accessory.color ? accessory.color.name : "Нет"}</p>
                <p className="my-1">Текущее количество: {accessory.quantity} шт</p>
              </div>
              <div className="col-6 text-end">
                <Image width={200} src={accessory?.image} />
              </div>
            </div>
            <AccessoryIncomeForm
              handleSubmit={handleSubmit}
              data={data}
              setData={setData}
              isPending={isPending}
              error={error}
            />
          </div>
        )}
        {isAccessoryPending && <p>Загрузка...</p>}
        {accessoryError && <p className="text-danger">{accessoryError}</p>}
      </Container>
    </div>
  );
};

export default AccessoryIncome;
