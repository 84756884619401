import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const Links = () => {
  return (
    <ListGroup>
      <ListGroup.Item>
        <Link className="text-decoration-none" to="/">Главная</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link className="text-decoration-none" to="/orders">Все заказы</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link className="text-decoration-none" to="/create-order">Создать заказ</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link className="text-decoration-none" to="/clients">Клиенты</Link>
      </ListGroup.Item>
      <ListGroup.Item>
        <Link className="text-decoration-none" to="/inventory">Склад</Link>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default Links;
