import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { API_ROUTES } from "../../settings/api";
import LoadingButton from "../LoadingButton";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const UpdateClientModal = ({ show, setShow, handleSuccess, client }) => {
  const [name, setName] = useState(client?.name);
  const [phone, setPhone] = useState(client?.phone_number);
  const axios = useAxiosPrivate();
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPending(true);
    axios
      .put(API_ROUTES.updateClient(client.id), { name, phone_number: phone })
      .then((res) => {
        setError(null);
        setIsPending(false);
        handleSuccess(res);
        setShow(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsPending(false);
      });
  };

  useEffect(() => {
    setName(client?.name);
    setPhone(client?.phone_number);
  }, [client])

  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="xl">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Header closeButton>
          <Modal.Title>Изменить Данные Клиента</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Имя</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите Имя"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Text className="text-muted">
              Убедитесь, что клиента нет еще в базе
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Телефон</Form.Label>
            <Form.Control
              type="phone"
              placeholder="9 цифр"
              maxLength={9}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          {isPending ? (
            <LoadingButton />
          ) : (
            <Button variant="primary" type="submit">
              Создать
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UpdateClientModal;
