import AsyncSelect from "react-select/async";
import { useState } from "react";
import { Form } from "react-bootstrap";
import CreateClientModal from "../ClientList/CreateClientModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"; // Make sure to import axios correctly
import { API_ROUTES } from "../../settings/api"; // Adjust the import based on your actual API routes file

const SelectClient = ({ setClient, defaultValue }) => {
  const [selectedClient, setSelectedClient] = useState(defaultValue);
  const [createModalShow, setCreateModalShow] = useState(false);
  const axios = useAxiosPrivate();

  const handleClientSelect = (client) => {
    setSelectedClient(client);
    setClient(client.value);
  };

  const handleCreateClientSuccess = (newClient) => {
    const newClientOption = {
      value: newClient.id,
      label: `${newClient.name} (${newClient.phone_number})`,
    };
    handleClientSelect(newClientOption);
    setCreateModalShow(false);
  };

  const searchClient = async (key) => {
    try {
      const res = await axios.get(API_ROUTES.getClients + `?search=${key}`);
      return res.data.map((client) => ({
        value: client.id,
        label: `${client.name} (${client.phone_number})`,
      }));
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const loadOptions = (inputValue, callback) => {
    searchClient(inputValue).then(callback);
  };

  return (
    <Form.Group className="p-3 my-3 border shadow rounded">
      <h4 className="mb-3 pb-1 border-bottom">Клиент</h4>
      {defaultValue ? (
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          onChange={handleClientSelect}
          value={selectedClient}
          required
          defaultValue={defaultValue}
        />
      ) : (
        <>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            onChange={handleClientSelect}
            value={selectedClient}
            required
          />
          <div className="mt-1">
            <span
              className="text-primary text-decoration-underline cursor-pointer"
              onClick={() => setCreateModalShow(true)}
            >
              Создать клиента
            </span>
          </div>
          <CreateClientModal
            show={createModalShow}
            setShow={setCreateModalShow}
            handleSuccess={handleCreateClientSuccess}
          />
        </>
      )}
    </Form.Group>
  );
};

export default SelectClient;
