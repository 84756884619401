import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { API_ROUTES } from "../../settings/api";
import { useState, useEffect } from "react";
import usePost from "../../hooks/usePost";
import LoadingButton from "../LoadingButton";

const AddPaymentForm = ({ orderId, handleSuccess, setShow }) => {
  const [amount, setAmount] = useState();
  const [comment, setComment] = useState();
  const { responseData, isPending, error, doPostRequest } = usePost();

  const formData = () => {
    let data = { amount: amount };
    if (comment) {
      data.comment = comment;
    }
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    doPostRequest(API_ROUTES.addPayment(orderId), formData());
  };

  useEffect(() => {
    if (responseData) {
      handleSuccess(responseData);
      setShow(false);
    }
  }, [responseData]) // Only call handleSuccess when responseData changes

  return (
    <Form className="my-3" onSubmit={(e) => handleSubmit(e)}>
      <InputGroup className="mb-3">
        <InputGroup.Text>$</InputGroup.Text>
        <Form.Control
          aria-label="Сумма"
          type="number"
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Введите сумму"
          required
          step={0.01}
        />
        <Form.Control
          aria-label="Комментарий"
          type="text"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Коммментарий"
        />
        {isPending ? (
          <LoadingButton />
        ) : (
          <button type="submit" className="btn btn-primary">
            Добавить
          </button>
        )}
      </InputGroup>
      {error && <p className="text-danger">{error}</p>}
    </Form>
  );
};

export default AddPaymentForm;
