import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import LoadingButton from "../LoadingButton";

const AccessoryIncomeForm = ({ handleSubmit, error, isPending, data, setData }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-around">
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Количество</Form.Label>
          <Form.Control
            type="number"
            required
            onChange={(e) => setData({ ...data, quantity: e.target.value })}
            placeholder="Количество прихода"
            value={data.quantity}
          />
        </Form.Group>
        <Form.Group as={Col} className="col-12 col-md-6 mb-3">
          <Form.Label>Закупочная цена</Form.Label>
          <Form.Control
            type="number"
            step={0.01}
            onChange={(e) => setData({ ...data, purchase_price: e.target.value })}
            placeholder="Цена закупки USD"
            value={data.purchase_price}
          />
        </Form.Group>
        <p className="text-end">
          {isPending ? (
            <LoadingButton />
          ) : (
            <button type="submit" className="btn btn-primary">
              Создать
            </button>
          )}
        </p>
      </Row>
    </Form>
  );
};

export default AccessoryIncomeForm;
